import { useTranslation, withTranslation } from "react-i18next";
import Charts from "./components/Charts";
import Statistics from "./components/Statistics";
import TransactionHistory from "./components/TransactionHistory";
import MainWrapper from "layout-pages/common/MainWrapper";
import { useListQuery } from "utils/hooks/useCrudQuery";
import { QUERY_DASHBOARD } from "utils/constants/react-query";
import HocApiData from "utils/hoc/HocApiData";
import AlertConfirmPhoneNumber from "./components/AlertConfirmPhoneNumber";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const user = useSelector(state => state.Login.user);
  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useListQuery({
    url: "users/dashboard",
    key: QUERY_DASHBOARD,
  });

  const { t } = useTranslation();
  return (
    <>
      <MainWrapper
        title={t("page.dashboard.index.title")}
        heading={t("page.dashboard.index.heading")}
      >
        <HocApiData loading={isLoading} error={isError} refetch={refetch}>
          {!user?.active && <AlertConfirmPhoneNumber />}
          <Statistics data={payload?.dashboard} />
          <TransactionHistory data={payload?.transactions} />
          <Charts data={payload?.charts} />
        </HocApiData>
      </MainWrapper>
    </>
  );
};

export default withTranslation()(Dashboard);
