import {
  AUTH_LOADING,
  USER_LOADED,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  AUTH_ERROR,
  LOGOUT,
  STOP_AUTH_LOADING,
  ADD_SHOP,
  UPDATE_SHOP,
  UPDATE_PROFIL,
  VALIDATE_CODE_SMS,
} from "./actionTypes";

const initialState = {
  isAuthenticated: false,
  token: localStorage.getItem("token"),
  isLoading: false,
  user: null,
  errors: null,
  smsCodeValidation: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case STOP_AUTH_LOADING:
      return {
        ...state,
        isLoading: false,
      };

    case USER_LOADED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        user: {
          ...action.payload,
          // role: { ...action.payload.role, name: "Customer" }, // Just for litiges
        },
        errors: null,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload?.token);
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload?.token,
        isLoading: false,
        user: action.payload?.user,
        errors: null,
      };

    case ADD_SHOP:
      return {
        ...state,
        user: { ...state.user, shop: action.payload },
      };

    case UPDATE_SHOP:
      return {
        ...state,
        user: {
          ...state.user,
          shop: { ...state.user.shop, ...action.payload },
        },
      };

    case UPDATE_PROFIL:
      const {
        first_name,
        last_name,
        username,
        email,
        phone_number,
        avatar,
        nni,
      } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          first_name,
          last_name,
          username,
          email,
          phone_number,
          avatar,
          nni,
        },
      };
    case VALIDATE_CODE_SMS:
      return { ...state, user: { ...state.user, active: 1 } };
    case AUTH_ERROR:
    case LOGIN_FAIL:
      localStorage.removeItem("token");

      return {
        ...state,
        isAuthenticated: false,
        token: null,
        isLoading: false,
        user: null,
        errors: action.payload,
      };

    case LOGOUT:
      localStorage.removeItem("token");
      return initialState;
    //--- Med 
    case VALIDATE_CODE_SMS:
      return {
        ...state,
        smsCodeValidation: action.payload,
      };

    default:
      return state;
  }
}

/*const updateListbyId = (updated, listData) => {
  return listData.map((item) => {
    if (updated.id === item.id) {
      return updated;
    } else {
      return item;
    }
  });
};*/
