import React from "react";
import TransactionHistory from "./components/TransactionHistory";

export default function Transaction() {
  return (
    <>
      <TransactionHistory />
    </>
  );
}
