import React, { useState } from 'react';
import PropTypes from "prop-types";
import { Row, Col ,Table ,  Dropdown,Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem} from "reactstrap";
import {  asyncPost } from "utils/helpers/api_helper";
import { showAlertMsg } from 'utils/hoc/Alerts';
import { reactQueryCacheCleaner } from "utils/hooks/useCrudQuery";
import { QUERY_PRODUCTS } from "utils/constants/react-query";


const ProductPreview = ({ product ,t, queryClient}) => {
  const [zoomedImage, setZoomedImage] = useState(product?.featured_image ? product?.featured_image :''); // Initial zoomed image
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [visibility, setVisibility] = useState(product?.visible ? product?.visible :  '0'); // Assuming '0' as the initial visibility state
  

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const toggleVisibility = (value) => {
    setVisibility(value);
    const formData = new FormData();
    formData.append('visible',value)
    asyncPost(`products/updateVisibility/${product?.id}`, formData, {}).then(res => {
        if (res) {
          showAlertMsg("success", t("page.common.toaster.success"));
        } else {
          showAlertMsg("danger", t("page.common.toaster.error"));
        }
        // from products page
        reactQueryCacheCleaner({
          queryClient: queryClient,
          resetKeys: [QUERY_PRODUCTS],
          invalidateKeys: [],
        });

      });

  };
  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipboard = async () => {
    try {
      // Attempt to copy the link text to the clipboard
      await navigator.clipboard.writeText(product.preview_url);
      showAlertMsg("success", t("page.common.toaster.success"));
      setCopySuccess('Link copied!');
    } catch (err) {
      setCopySuccess('Failed to copy!');
      showAlertMsg("danger", t("page.common.toaster.error"));
    }
  };

  const handleImageClick = (imgUrl) => {
    setZoomedImage(imgUrl); // Update the zoomed image
  };

  if (!product) {
    return <div>{t('page.common.spinner.onLoadingLite')}</div>;
  }

  return (
    <div>
        <style>
          {`
            #preview > #selected {
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              transition: box-shadow 0.3s ease-in-out;
            }
            #imgs img {
              transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
            }
            #imgs img:hover {
              transform: scale(1.05);
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            }
          `}
        </style>
        <Row>
            <Col md="6" id="preview">  
                <div id='selected' style={{ height: '300px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px' }}>
                    <img id='zoomed' src={zoomedImage} alt="Zoomed" style={{ maxHeight: '100%', maxWidth: '100%' }} />
                </div>
                <hr/>
                <h6>{t('ecommerce.display.otherImgTitle')}</h6>
                
                <Row id='imgs'>
                    <Col md="3" style={{ borderRadius: '5px', paddingLeft:'5px' ,borderRight:"1px solid #E6E7EA" ,borderBottom:"1px solid #E6E7EA"}}  onClick={() => handleImageClick(product.featured_image)}>
                        <img src={product.featured_image} alt='Featured' height="70" style={{cursor: 'pointer'}} />
                    </Col>
                    {product.gallery && product.gallery.map((image, index) => (
                        <Col key={index + 1}  md="3" onClick={() => handleImageClick(image.name)} style={{ borderRadius: '5px', paddingLeft:'5px' ,borderRight:"1px solid #E6E7EA" ,borderBottom:"1px solid #E6E7EA"}}>
                            <img src={image.name} alt={`Gallery ${index}`} height="70" style={{cursor: 'pointer'}} />
                        </Col>
                    ))}
                </Row>  
                
            </Col>
            <Col md="6">
                 <h2> <span>#{product.id}</span> - {product.title_fr} </h2> 
                 <h4 style={{color:'#d96e28'}}> {product.price} <small><i>Mru</i></small> </h4> 
                 <hr/>
                    <Table md="12" striped>
                        <tbody>

                        
                        <tr><th>{t('ecommerce.display.stock')} </th><td><b> {product.stock}</b></td> </tr>
                        <tr><th>{t('ecommerce.display.visibility')}</th>
                            <td id="visibility">
                                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle caret>
                                        {visibility === '0' ? t('ecommerce.display.visibility.off') : t('ecommerce.display.visibility.on')}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => toggleVisibility('0')}>{t('ecommerce.display.visibility.off')}</DropdownItem>
                                        <DropdownItem onClick={() => toggleVisibility('1')}>{t('ecommerce.display.visibility.on')}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </td> 
                        </tr>
                        <tr><th>{t('ecommerce.display.ads')} </th>
                            <td style={{'color': product.advert ? 'green' :'black'}}>
                                <b style={{paddingTop:'5px'}}> {product.advert ?t('ecommerce.display.ads.on') : t('ecommerce.display.ads.off') }</b>
                            </td> 
                        </tr>
                        </tbody>
                        <tfoot><tr><td>
                            {t('ecommerce.display.preview.share')}
                            </td><td>
                        <a href={product.preview_url} target="_blank" rel="noopener noreferrer">{t('ecommerce.display.preview.title')}</a>
                        <Button onClick={copyToClipboard} ><i className="bx bx-copy " /></Button>

                            </td></tr></tfoot>
                    </Table>
                 <hr/>
                 <Row>
                    <Col md="6" style={{borderRight:'1px solid #E6E7EA'}} > <h6>{t('ecommerce.display.resume_fr')}</h6>
                    <p> {product.summary_fr ? product.summary_fr : '-' }</p>
                    </Col>

                    <Col md="6" > <h6>{t('ecommerce.display.resume_ar')}</h6>
                    <p> {product.summary_ar ? product.summary_ar : '-' }</p>
                    </Col>
                </Row>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col md="6" > <h5>{t('ecommerce.display.desc_fr')}</h5>
            <p> {product.description_fr ? product.description_fr : '-' }</p>
            </Col>

            <Col md="6"  style={{borderLeft:'2px solid #E6E7EA'}} > <h5>{t('ecommerce.display.desc_ar')}</h5>
            <p> {product.description_ar ? product.description_ar : '-' }</p>
            </Col>
        </Row>  
    </div>
  );
};

ProductPreview.propTypes = {
    product: PropTypes.object,
    t: PropTypes.any,
    queryClient:PropTypes.any
};

export default ProductPreview;
