import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import Receiver from "./Receiver";
import Sender from "./Sender";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { QUERY_LITIGE_ID } from "utils/constants/react-query";
import { useSelector } from "react-redux";
import HocApiData from "utils/hoc/HocApiData";
import { useGetQuery } from "utils/hooks/useCrudQuery";
import { asyncPost } from "utils/helpers/api_helper";
import { showAlertMsg } from "utils/hoc/Alerts";
import { useTranslation } from "react-i18next";

// const chatData = [
//   {
//     id: 1,
//     name: "Steven Franklin",
//     msg: "Bonjour, je veux retouner l'article que j'ai commandé hier",
//     time: "10.00",
//     isSender: false,
//   },
//   {
//     id: 2,
//     name: "Admin",
//     msg: "Bonjour, Madame est ce que vous avez une ganartie ?",
//     time: "10.02",
//     isSender: true,
//   },
//   {
//     id: 3,
//     name: "Steven Franklin",
//     msg: "Le produit est défectueux. La fermeture ne marche pas",
//     time: "10.06",
//     isSender: false,
//   },
//   {
//     id: 4,
//     name: "Steven Franklin",
//     msg: "Oui, j'ai une ganratie de 10 jours.",
//     time: "10.06",
//     isSender: false,
//   },
//   {
//     id: 5,
//     name: "Admin",
//     msg: "D'accord , Votre demande rembourssemnt est pris compte. Avez vous d'autre question ?",
//     time: "10.07",
//     isSender: true,
//   },
// ];

// todo check sent_by_customer or customer_id
// todo ( get customer name )
// todo resolve litige

// todo show justice files
// todo send message with joint file

function ChatBox(props) {
  const [messages, setMessages] = useState([]);
  const [sendMsgLoading, setSendMsgLoading] = useState(false);
  const [newMsg, setNewMsg] = useState("");
  const user = useSelector(state => state.Login.user);
  const messageBox = useRef(null);
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useGetQuery(
    {
      url: "users/litiges/" + props.current?.id,
      id: props.current?.id,
      key: QUERY_LITIGE_ID,
    },
    !!props.current?.id
  );

  useEffect(() => {
    if (payload?.messages) {
      setMessages(payload.messages);
      setTimeout(() => scrollToBottom(), 500);
    }
  }, [payload]);

  const scrollToBottom = () => {
    if (messageBox.current) {
      messageBox.current.scrollTop = messageBox.current.scrollHeight + 1000;
    }
  };

  const addMsg = newMsg => {
    const modifiedMessages = [...messages, newMsg];
    setMessages(modifiedMessages);
    setNewMsg("");
    setTimeout(() => scrollToBottom(), 500);
  };

  const onSendMessage = () => {
    const payload = {
      litige_id: props.current?.id,
      customer_id: user?.id,
      msg: newMsg,
      file: null,
    };
    setSendMsgLoading(true);
    asyncPost("users/messenger", payload).then(res => {
      const resKeys = Object.keys(res?.data);
      if (res?.data && !resKeys.includes("error")) {
        setSendMsgLoading(false);
        addMsg(res?.data);
      } else {
        setSendMsgLoading(false);
        showAlertMsg("danger", t("page.common.toaster.chatNotSend"));
      }
    });
  };

  return (
    <>
      <Col xl="12" style={{ height: "100%" }}>
        <Card style={{ height: "100%" }}>
          <CardBody>
            <Row>
              <Col md="4" xs="9">
                <h5 className="font-size-15 mb-1">
                  {props.current &&
                    `${props.current?.order.first_name} ${props.current?.order.last_name}`}
                </h5>
                <p className="text-muted mb-0">
                  <i className="mdi mdi-circle text-success align-middle me-1" />
                  {t("page.common.btn.active")}
                </p>
              </Col>
              <Col md="8" xs="3">
                <ul className="d-flex justify-content-end">
                  <Button color="danger" className="text-white px-2 py-2">
                    {t("page.common.btn.conflitSolved")}{" "}
                    <i className="mdi mdi-check font-size-16 text-primary text-white" />
                  </Button>
                </ul>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="pb-0">
            <HocApiData
              loading={isLoading}
              error={isError}
              isEmpty={payload?.messages?.length === 0}
              refetch={refetch}
            >
              <div>
                <div className="chat-conversation">
                  <PerfectScrollbar
                    style={{ marginBottom: "1rem", maxHeight: "700px" }}
                    containerRef={el => (messageBox.current = el)}
                    // ref={messageBox}
                    //containerRef={ref => (this.messageBox = ref)}
                  >
                    <ul className="list-unstyled">
                      {/* <li>
                        <div className="chat-day-title">
                          <span className="title">Today</span>
                        </div>
                      </li> */}
                      {(messages || []).map((message, index) => (
                        <React.Fragment key={index}>
                          {message.customer_id == user?.id ? (
                            <Sender message={message} />
                          ) : (
                            <Receiver message={message} />
                          )}
                        </React.Fragment>
                      ))}
                    </ul>
                  </PerfectScrollbar>
                </div>
              </div>
            </HocApiData>
          </CardBody>

          <div
            className="p-3 chat-input-section"
            //style={{ position: "sticky" }}
          >
            <Row>
              <Col>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control rounded chat-input"
                    placeholder={t("forms.input.sendChatMsg.placeholder")}
                    value={newMsg}
                    disabled={sendMsgLoading}
                    onChange={e => {
                      setNewMsg(e.target.value);
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        onSendMessage();
                      }
                    }}
                  />
                </div>
              </Col>
              <div className="col-auto">
                <Button
                  type="submit"
                  color="primary"
                  className="chat-send w-md"
                  onClick={() => onSendMessage()}
                  disabled={sendMsgLoading}
                >
                  <span className="d-none d-sm-inline-block me-2">
                    {t("page.common.btn.send")}
                  </span>
                  <i className="mdi mdi-send" />
                </Button>
              </div>
            </Row>
          </div>
        </Card>
      </Col>
    </>
  );
}

ChatBox.propTypes = {
  messages: PropTypes.any,
  current: PropTypes.any,
};

export default ChatBox;
