import React, { useState } from "react";
import { Card, Button } from "reactstrap";
import FormAddShop from "./components/FormAddShop";
import MainWrapper from "layout-pages/common/MainWrapper";
import { useSelector } from "react-redux";
import CustomModal from "layout-pages/common/CustomModal";

export default function AddShop() {
  const user = useSelector(state => state.Login.user);
  const [showModal, setShowModal] = useState(!user?.shop);

  //show Modal
  return (
    <>
      <CustomModal
        title={"Guide utilisateur"}
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="lg"
        closeText="J'ai compris"
        closeBtnColor="primary"
        content={
          <>
            <div className="m-3">
              <h5>
                Creér votre premier boutique pour démarer votre buisness !
              </h5>
            </div>
          </>
        }
      />
      <MainWrapper
        title={"Creation de votre boutique"}
        heading={"Creation de votre boutique"}
      >
        <Card className="p-3">
          {!user?.shop ? (
            <FormAddShop />
          ) : (
            <div className="my-2">
              <h5 className="text-primary">
                Vous avez déja une boutique a votre nom !
              </h5>
            </div>
          )}
        </Card>
      </MainWrapper>
    </>
  );
}
