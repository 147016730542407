import React, { useEffect } from "react";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "reactstrap";
import { CustomTextInput } from "../../../../common/GenericInputForm";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { showAlertMsg } from "../../../../../utils/hoc/Alerts";
import { VALIDATE_CODE_SMS } from "store/auth/login/actionTypes";
import { asyncPost } from "utils/helpers/api_helper";
import { useTranslation } from "react-i18next";
import CustomSelect from "layout-pages/common/CustomSelect";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    account_number: Yup.string()
      .required("Le Numéro de compte est obligatoire !")
      .min(6,"Le numéro de compte doit être au minimun de 6 carractéres")
      .max(20, "Nombre de caractères 20 limite dépassé !"),
    account_number_repeat: Yup.string()
      .required("Le Numéro de compte est obligatoire !")
      .min(6,"Le numéro de compte doit être au minimun de 6 carractéres")
      .oneOf([Yup.ref('account_number'), null], "Les numéros de compte doivent être identiques"),

      internet_code: Yup.string()
      .required("code internet est obligatoire !")
      .matches(/^\d{4}$/, "Le code internet doit être un nombre de 4 chiffres"),
    
  }),
  mapPropsToValues: props => ({
    account_number:  "",
    account_number_repeat:  "",
    internet_code:  "",

  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const payload = {
      ...values,
    };

    /* form data */
    const formData = new FormData();
    formData.append("payWithId", payload.payWithId);
    formData.append("account_number", payload.account_number);
    formData.append("account_number_repeat", payload.account_number_repeat);
    formData.append("internet_code", payload.internet_code);

    //
    //console.log(payload)
    asyncPost("userbank", formData, {
      "Content-Type": "multipart/form-data",
    }).then(res => {
      //console.log(res?.data);
      props.dispatch({
        type: VALIDATE_CODE_SMS,
        payload: res?.data?.response,
      });

      if (res?.data?.success) {
        setSubmitting(false);
        //---- 
        props.setSmsCode(res?.data)
       // props.setFileUpload(null);
        showAlertMsg("success", props.t("page.common.toaster.success"));
      } else {
       // props.setFileUpload(null);
        showAlertMsg("danger", props.t("page.common.toaster.error"));
        setSubmitting(false);
      }
    });
  },
  displayName: "MyForm",
});

const MyForm = props => {

  /*
  useEffect(() => {
    props.setFieldValue("avatar", props.fileUpload);
  }, [props.fileUpload]);
*/

  return (
    <>
    <Form onSubmit={props.handleSubmit} className="px-sm-4 py-sm-4 px-1 py-1">
      <Row>
        <Col md={12} lg={6}>
          <CustomTextInput
            type="text"
            className="form-control"
            name="account_number"
            label={props.t("page.bank.index.formAccount")}
            required
          />
          <CustomTextInput
            type="text"
            className="form-control"
            name="account_number_repeat"
            label={props.t("page.bank.index.formAccountRepeat")}
            required
          />
        </Col>
        <Col md={12} lg={6}>
        
          <CustomSelect
            className="form-control"
            name="payWithId"
            label={props.t("page.bank.index.formBank")}
            required
            options={props.bankType}
            disabled={false}
          />
          <CustomTextInput
            type="password"
            className="form-control"
            name="internet_code"
            label={props.t("page.bank.index.cashoutInternetPsw")}
            required
          />
        
        </Col>
      </Row>

      {props.isSubmitting ? (
        <Button
          color="primary"
          disabled={props.isSubmitting}
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          {props.t("page.common.spinner.onLoadingLite")}
        </Button>
      ) : (
        <Button
          color="primary"
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          {props.t("page.bank.index.formbut")}
        </Button>
      )}
    </Form>
    </>
  );
};

MyForm.propTypes = {
  handleSubmit: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  errors: PropTypes.any,
  isSubmitting: PropTypes.any,
  fileUpload: PropTypes.any,
  setSmsCode: PropTypes.any,
  dispatch: PropTypes.any,
  t: PropTypes.any,
  bankType: PropTypes.array
};

const MyEnhancedForm = formikEnhancer(MyForm);

const FormAddBankAccount = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return <MyEnhancedForm t={t} {...props} dispatch={dispatch} />;
};

export default FormAddBankAccount;
