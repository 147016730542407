import React, { useState, useEffect } from "react";
import MainWrapper from "layout-pages/common/MainWrapper";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useSelector } from "react-redux";
import avatar from "../../../../assets/images/placeholder.png";
import qrcode_placeholder from "../../../../assets/images/qrcode_placeholder.png";
import FormEditProfile from "./components/FormEditProfile";
import FormEditPswd from "./components/FormEditPswd";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const { t } = useTranslation();
  const user = useSelector(state => state.Login.user);
  const [fileUpload, setFileUpload] = useState(null);
  const [preview, setPreview] = useState();
  const [showPrivateKey, setShowPrivateKey] = useState(false);

  const hiddenFileInputProfil = React.useRef(null);
  const handleProfilClick = _ => {
    hiddenFileInputProfil.current.click();
  };

  const handleProfilChange = event => {
    if (!event.target.files || event.target.files.length === 0) {
      setFileUpload(null);
      return;
    }
    setFileUpload(event.target.files[0]);
  };

  useEffect(() => {
    if (!fileUpload) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(fileUpload);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileUpload]);

  return (
    <>
      <MainWrapper
        title={t("page.profile.index.title")}
        heading={t("page.profile.index.heading")}
      >
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="me-3 d-flex flex-column align-items-center justify-content-center">
                    <div>
                      <img
                        src={fileUpload ? preview : user?.avatar}
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = avatar;
                        }}
                        alt="mon profil"
                        className="avatar-lg rounded-circle img-thumbnail"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        name="avatar"
                        ref={hiddenFileInputProfil}
                        onChange={handleProfilChange}
                        style={{ display: "none" }}
                      />
                    </div>
                    <div className="">
                      <Button
                        color="primary"
                        className="text-white px-2 py-0 mt-1"
                        onClick={handleProfilClick}
                      >
                        <i className="mdi mdi-upload d-block font-size-13"></i>
                      </Button>
                    </div>
                  </div>

                  <div className="align-self-center flex-1">
                    {user && (
                      <div className="text-muted">
                        <h5>{`${user?.first_name} ${user?.last_name}`}</h5>
                        <p className="mb-1"> {`#${user?.username}`}</p>
                        <p className="mb-0">{`${user?.email}`}</p>
                      </div>
                    )}
                  </div>

                  <div style={{ marginLeft: "auto" }}>
                    <img
                      src={user?.qr_code_url}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = qrcode_placeholder;
                      }}
                      alt="mon profil"
                      className="avatar-lg img-thumbnail"
                    />
                    <div className="mt-2 text-center">
                      {t("page.profile.index.qrCode")}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <h5>{t("page.profile.index.userSettings")}</h5>
            <FormEditProfile
              editData={user}
              fileUpload={fileUpload}
              setFileUpload={setFileUpload}
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h5>{t("page.profile.index.psswdSettings")}</h5>
            <FormEditPswd />
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h5>{t("page.profile.index.keysSettings")}</h5>
            <div className="px-sm-4 py-sm-4 px-1 py-1">
              <Row>
                <Col md={12} lg={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="public_key">
                      {t("page.profile.index.publicKey")}
                    </Label>
                    <Input
                      name="public_key"
                      type="text"
                      className="form-control"
                      value={user?.public_key}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={12} lg={6}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="private_key">
                      {t("page.profile.index.privateKey")}
                    </Label>
                    <div className="d-flex align-items-center">
                      <Input
                        name="private_key"
                        type={showPrivateKey ? "text" : "password"}
                        className="form-control"
                        style={{ marginRight: "12px" }}
                        value={user?.secret_key}
                        disabled
                      />
                      <Button
                        color="primary"
                        className="text-white px-1 py-0"
                        onClick={() => setShowPrivateKey(!showPrivateKey)}
                      >
                        {showPrivateKey ? (
                          <i className="mdi mdi-eye-off font-size-16" />
                        ) : (
                          <i className="mdi mdi-eye font-size-16" />
                        )}
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </MainWrapper>
    </>
  );
}
