import React from "react";
import RevenuChart from "./RevenuChart";
import PropTypes from "prop-types";
import { Row } from "reactstrap";
import RevenuSource from "./RevenuSource";
import Insight from "./Insight";

function Charts({ data }) {
  return (
    <>
      <div className="my-4"></div>
      <Row>
        <RevenuChart data={data?.revenus} />
        <RevenuSource data={data?.revenus_source} />
        <Insight data={data?.apercus} />
      </Row>
    </>
  );
}

Charts.propTypes = {
  data: PropTypes.any,
};

export default Charts;
