import { HAS_ERROR, IS_LOADING, FETCH_CITIES } from "./actionTypes";

const initialState = {
  cities: {
    isLoading: false,
    error: null,
    payload: [],
  },
};

const ressources = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      if (action.payloadType === FETCH_CITIES) {
        state = {
          ...state,
          cities: { ...state.cities, isLoading: true },
        };
      }
      break;
    case HAS_ERROR:
      if (action.payloadType === FETCH_CITIES) {
        state = {
          ...state,
          cities: {
            ...state.cities,
            error: action.payload,
            isLoading: false,
          },
        };
      }
      break;
    case FETCH_CITIES:
      state = {
        ...state,
        cities: {
          ...state.cities,
          error: null,
          isLoading: false,
          payload: action.payload,
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ressources;
