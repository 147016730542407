import { activateParentDropdown } from "utils/helpers/ui";
import MetisMenu from "metismenujs";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { ADMIN, CUSTOMER, SELLER } from "utils/constants/global";
import { useSelector } from "react-redux";

const SidebarContent = props => {
  const ref = useRef();

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  const user = useSelector(state => state.Login.user);

  const sidebarMenu = [
    {
      label: props.t("layout.sidebarContent.dashboard"),
      icon: "bx bx-bar-chart-alt-2",
      path: "/dashboard",
      roles: [ADMIN, CUSTOMER,SELLER],
    },

    // ** hidden temporarily
     {
       label: props.t("layout.sidebarContent.ecommerce"),
       icon: "bx bxs-basket",
       path: "/add/shop",
       hide: user?.shop, // hide if shop already created
       roles: [CUSTOMER,SELLER,ADMIN],
     },
     {
       label: props.t("layout.sidebarContent.ecommerce"),
       icon: "bx bxs-basket",
       roles: [CUSTOMER,SELLER,ADMIN],
       hide: !user?.shop, // hide shop if is not created yet
       children: [
         { label:props.t("layout.sidebarContent.shops"), path: "/shops", icon: "bx bx-store-alt" },
         { label: props.t("layout.sidebarContent.products"), path: "/products", icon: "bx bx-shopping-bag" },
         {
           label: props.t("layout.sidebarContent.categories"),
           path: "/categories",
           icon: "bx bx-archive",
         },
       ],
     },

    // ** unsed
    

     {
       label: props.t("layout.sidebarContent.balance"),
       icon: "bx bx-money",
       path: "/balance",
       roles: [CUSTOMER,SELLER,ADMIN],
     },
    {
      label: props.t("layout.sidebarContent.transactions"),
      icon: "bx bx-transfer",
      path: "/transactions",
      roles: [CUSTOMER,SELLER,ADMIN],
    },

    {
      label: props.t("layout.sidebarContent.customers"),
      icon: "bx bxs-user-circle",
      path: "/customers",
      roles: [CUSTOMER,SELLER,ADMIN],
    },

    {
      label: props.t("layout.sidebarContent.conflits"),
      icon: "bx bx-angry",
      path: "/conflits",
      roles: [CUSTOMER,SELLER,ADMIN],
    },

    {
      label: props.t("layout.sidebarContent.settings"),
      icon: "bx bxs-user-circle",
      roles: [CUSTOMER,SELLER,ADMIN],
      children: [
        {
          label: props.t("layout.sidebarContent.bankaccount"),
          path: "/bank",
          icon: "bx bxs-bank",
        },

        {
          label: props.t("layout.sidebarContent.profile"),
          path: "/profile",
          icon: "bx bxs-user-detail",
        },
        //{ label: props.t("layout.sidebarContent.accounts"), path: "/accounts", icon: "bx bx-credit-card" },
      ],
    },
  ];

  return (
    <SimpleBar className="h-100" ref={ref}>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t("layout.sidebar.Menu")}</li>
          {sidebarMenu
            .filter(
              item => item.roles.includes(user?.role?.slug) && !item?.hide
            )
            .map((menu, index1) => {
              if (menu?.children) {
                return (
                  <li key={index1}>
                    <Link to="/#" className="">
                      <i className={menu.icon}></i>
                      <span>{menu.label}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      {menu?.children.map((link, index2) => {
                        return (
                          <li key={index2}>
                            <Link to={link.path}>
                              <i className={link.icon}></i>
                              <span>{link.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              } else {
                return (
                  <li key={index1}>
                    <Link to={menu?.path}>
                      <i className={menu.icon}></i>
                      <span>{menu.label}</span>
                    </Link>
                  </li>
                );
              }
            })}
        </ul>
      </div>
    </SimpleBar>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
