import React, { useEffect } from "react";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "reactstrap";
import { CustomTextInput } from "../../../../common/GenericInputForm";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { showAlertMsg } from "../../../../../utils/hoc/Alerts";
import { UPDATE_PROFIL } from "store/auth/login/actionTypes";
import { asyncPost } from "utils/helpers/api_helper";
import { useTranslation } from "react-i18next";
import CustomSelect from "layout-pages/common/CustomSelect";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({

    token: Yup.string()
    .required("Le token SMS est obligatoire !"),
      
    sms_code: Yup.string()
    .required("Le code SMS est obligatoire !")
    .matches(/^\d{4,6}$/, "Le code SMS doit être un nombre de 4 chiffres minimum"),
  
  }),
  mapPropsToValues: props => ({
    bank_id: props.editData?.id || "",
    payWithId: props.editData?.account_id || "",
    token: props.editData?.token || "",
    sms_code:  "",
    //---CASH-OUT
    amount:  props.editData?.cashout?.amount || "",
    internet_code:  props.editData?.cashout?.internet_code || "",
    payWith:  props.editData?.cashout?.payWith || "",

  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const payload = {
      ...values,
    };

    /* form data */
    const formData = new FormData();
    formData.append("bank_id", payload.bank_id);
    formData.append("token", payload.token);
    formData.append("payWithId", payload.payWithId);
    formData.append("sms_code", payload.sms_code);
    if(props.isCashout){
      formData.append("amount", payload.amount);
      formData.append("internet_code", payload.internet_code);
      formData.append("payWith", payload.payWith);
    }
    //
    asyncPost( props.isCashout ? "cashout":"userbank", formData, {
      "Content-Type": "multipart/form-data",
    }).then(res => {
     // console.log(res?.data)
      if (res?.data?.success) {
        setSubmitting(false);
        props.setSmsCode(null);
        showAlertMsg("success", props.t("page.common.toaster.success"));
      } else {
        showAlertMsg("danger", props.t("page.common.toaster.error"));
        setSubmitting(false);
      }
    });
  },
  displayName: "MyForm",
});

const MyForm = props => {
 // console.log("Send SMS ")
  //console.log(props.editData)

  return (
    <Form onSubmit={props.handleSubmit} className="px-sm-4 py-sm-4 px-1 py-1">
      <Row>
        <Col md={12} lg={8}>
          <CustomTextInput
            type="number"
            className="form-control"
            name="sms_code"
            label={props.t("page.bank.index.cashoutsmsCode")}
            required
          />
          <CustomTextInput
            type="hidden"
            className="form-control"
            name="bank_id"
            required
          />

          <CustomTextInput
            type="hidden"
            className="form-control"
            name="token"
            required
          />
          <CustomTextInput
            type="hidden"
            className="form-control"
            name="payWithId"
            required
          />
        </Col>
        
      </Row>

      {props.isSubmitting ? (
        <Button
          color="primary"
          disabled={props.isSubmitting}
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          {props.t("page.common.spinner.onLoadingLite")}
        </Button>
      ) : (
        <Button
          color="primary"
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          {props.t( props.isCashout? "page.bank.index.cashoutConfBut":"page.bank.index.formbut")}
        </Button>
      )}
    </Form>
  );
};

MyForm.propTypes = {
  handleSubmit: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any,
  editData: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  errors: PropTypes.any,
  isSubmitting: PropTypes.any,
  fileUpload: PropTypes.any,
  setSmsCode: PropTypes.any,
  isCashout: PropTypes.bool,
  dispatch: PropTypes.any,
  t: PropTypes.any,
};

const MyEnhancedForm = formikEnhancer(MyForm);

const FormSmsValidation = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return <MyEnhancedForm t={t} {...props} dispatch={dispatch} />;
};

export default FormSmsValidation;
