import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatApiDate, isValidHttpUrl } from "utils/helpers/utils";

export default class Sender extends Component {
  render() {
    const { message } = this.props;
    const file = this.props.message.file?.url;
    const cdn = process.env.REACT_APP_CDN;

    return (
      <>
        {file && (
          <li>
            <img
              className="rounded me-2 mb-2"
              alt="Img"
              width="200"
              src={isValidHttpUrl(file) ? file : cdn + file}
            />
          </li>
        )}
        <li className="right">
          <div className="conversation-list">
            <div className="ctext-wrap" style={{ backgroundColor: "#34c38f" }}>
              <p className="text-white">{message["msg"]}</p>

              <p className="chat-time mb-0 text-white">
                <i className="bx bx-time-five align-middle me-1" />{" "}
                {formatApiDate(message["updated_at"])}
              </p>
            </div>
          </div>
        </li>
      </>
    );
  }
}

Sender.propTypes = {
  message: PropTypes.any,
};
