import React from "react";
import { useQueryClient } from "react-query";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ProductPreview from "./ProductPreview";


const FormShowProduct = props => {
  
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // Accessing product from props
  const { product } = props;
  
  return <ProductPreview 
  product={product} 
  t={t} 
  queryClient={queryClient}
  />

};

// Define PropTypes for FormShowProduct
FormShowProduct.propTypes = {
  product: PropTypes.object, // Mark it as required if it is always expected
  closeModal: PropTypes.func, // If you have closeModal prop being passed, define it as well
  // Define any other props your component expects
};

export default FormShowProduct;
