// users roles slugs
export const ADMIN = "admin";
export const CUSTOMER = "customer";
export const SELLER = "seller";

// cdn
export const CDN = process.env.REACT_APP_CDN;

// pagination default options
export const DEFAULT_LIMIT = 5;
export const DEFAULT_ORDER_BY = "createdAt";
export const DEFAULT_ORDER_DIRECTION = "desc"; //asc
