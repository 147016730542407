import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";
import { TableHead, TableBody } from "layout-pages/common/Table";
import {
  formatApiDate,
  numberWithThousandSeparator,
} from "utils/helpers/utils";
import HocApiData from "utils/hoc/HocApiData";
import { ORDER_STATUS_MAPPER } from "utils/constants/enum";

function TransactionHistory({ data }) {
  const { t } = useTranslation();
  const tableHead = [
    t("page.common.tableHeading.date"),
    t("page.common.tableHeading.merchant"),
    t("page.common.tableHeading.customer"),
    t("page.common.tableHeading.amount"),
    t("page.common.tableHeading.status"),
    t("page.common.tableHeading.actions"),
  ];

  return (
    <>
      <HocApiData loading={false} error={false} isEmpty={data?.length === 0}>
        <h4 className="mt-3 mb-2">{t("page.dashboard.history.title")}</h4>
        <Row>
          <Col lg="12">
            <div className="table-responsive">
              <Table className="project-list-table table-nowrap align-middle table-borderless mb-0">
                <TableHead cols={tableHead} />
                <TableBody data={data || []} TableRow={TableRow} />
              </Table>
            </div>
          </Col>
        </Row>
      </HocApiData>
    </>
  );
}
TransactionHistory.propTypes = {
  data: PropTypes.array,
};

function TableRow({ item }) {
  const { t } = useTranslation();
  return (
    <tr>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {formatApiDate(item.date)}
        </p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">{`${item?.client}`}</p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">{item?.commande}</p>
      </td>
      <td>
        <h5 className="text-truncate font-size-14 mb-0">
          {numberWithThousandSeparator(item.totale)}{" "}
          <span className="ml-2">MRU</span>
        </h5>
      </td>
      <td>
        <Badge
          className={
            "font-size-13 badge-soft-" +
            ORDER_STATUS_MAPPER[item?.status]?.backgroundColor
          }
          color={ORDER_STATUS_MAPPER[item?.status]?.backgroundColor}
          pill
        >
          {t(ORDER_STATUS_MAPPER[item.status]?.label)}
        </Badge>
      </td>
      <td>
        <UncontrolledDropdown style={{ cursor: "pointer" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#">
              <i className="mdi mdi-eye font-size-16 text-primary me-2" />
              {t("page.common.btn.details")}
            </DropdownItem>
            {/* <DropdownItem href="#">
              <i className="mdi mdi-pencil font-size-16 text-success me-2" />
              Modifier
            </DropdownItem>
            <DropdownItem href="#">
              <i className="mdi mdi-trash-can font-size-16 text-danger me-2" />
              Supprimer
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  item: PropTypes.object,
};

export default TransactionHistory;
