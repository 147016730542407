import { useEffect, Suspense } from "react";
import { printEnvMode } from "utils/helpers/environment";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
//import "./assets/scss/theme.scss";
import Layout from "./layout-blueprint/layout/SidebarLayout";
import NonAuthLayout from "./layout-blueprint/layout/NonAuthLayout";
import {
  adminProtectedRoutes,
  customerProtectedRoutes,
  publicRoutes,
} from "./layout-pages/routes";
import Authmiddleware from "./layout-pages/routes/route";
import { getUserData } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN, CUSTOMER, SELLER } from "utils/constants/global";
import MainWrapper from "./layout-pages/common/MainWrapper";
import { getCountries } from "store/auth/ressources/actions";
import { useTranslation } from "react-i18next";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector(state => state.Login.user);
  const isAuthenticated = useSelector(state => state.Login.isAuthenticated);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUserData());

    // prefecting ressources
    dispatch(getCountries());

    printEnvMode();
  }, []);

  const protectedPaths = customerProtectedRoutes
    .concat(adminProtectedRoutes)
    .map(item => {
      return item.path;
    });
  return (
    <>
      <Suspense fallback={t("page.common.spinner.onLoading")}>
        <Switch>
          {/* <Redirect exact from="/" to="/balance" /> */}

          {/* Public routes */}
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {/* Handle authentfication of user before render components */}
          {(user?.role.slug === CUSTOMER || user?.role.slug == SELLER )&&
            customerProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                isVerifiedAccountGuard={route.isVerifiedAccountGuard}
                key={idx}
                exact
              />
            ))}

          {user?.role.slug === ADMIN &&
            adminProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                isVerifiedAccountGuard={route.isVerifiedAccountGuard}
                key={idx}
                exact
              />
            ))}

          {/* Handle role of user before render components or redirect it to login page  */}
          {isAuthenticated ? (
            <>
              <Route
                render={() => (
                  <Layout>
                    <MainWrapper title={t("page.common.403.msg")}>
                      <h1 className="text-danger">
                        {t("page.common.403.msg")}
                      </h1>
                    </MainWrapper>
                  </Layout>
                )}
              />
            </>
          ) : (
            <Route
              render={() => (
                <Redirect
                  exact
                  to={{
                    pathname: "/login",
                    state: { from: location.pathname },
                  }}
                />
              )}
            />
          )}

          {/*  !! fixme this route never reatched */}
          <Route
            render={() => (
              <div className="d-flex align-items-center justify-content-center m-5">
                <h1 className="text-danger">{t("page.common.404.msg")}</h1>
              </div>
            )}
          />
        </Switch>
      </Suspense>
    </>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

export default App;

/*
<Router>
  <Route path={routesPaths}>
    <Switch>
      {publicRoutes.map((route, idx) => (
        <Authmiddleware
          path={route.path}
          layout={NonAuthLayout}
          component={route.component}
          key={idx}
          isAuthProtected={false}
          exact
        />
      ))}

      {user?.role.name === CUSTOMER &&
        customerProtectedRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            exact
          />
        ))}

      {user?.role.name === ADMIN &&
        adminProtectedRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={Layout}
            component={route.component}
            key={idx}
            exact
          />
        ))}

      //user have not guard to this route or is not authenticated
      {isAuthenticated ? (
        <>
          <Route
            render={() => (
              <Layout>
                <MainWrapper title={"Page non autorisée !"}>
                  <h1 className="text-danger"> Page non autorisée ! </h1>
                </MainWrapper>
              </Layout>
            )}
          />
        
        </>
      ) : (
        <Route render={() => <Redirect exact to="/login" />} />
      )}

      <Route
        render={() => (
          <div className="d-flex align-items-center justify-content-center m-5">
            <h1 className="text-danger"> Page non trouvée ! </h1>
          </div>
        )}
      />
    </Switch>
  </Route>
</Router>
*/
