import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function RevenuSource({ data }) {
  const { t } = useTranslation();
  const chartData = {
    series: data?.data || [], //[56, 38, 26],
    options: {
      maintainAspectRatio: false,
      labels: data?.labels || [],
      colors: ["#556ee6", "#34c38f", "#f46a6a"],
      legend: { show: !1 },
      plotOptions: {
        pie: {
          donut: {
            size: "40%",
          },
        },
      },
    },
  };
  return (
    <>
      <Col xl="4">
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">
              {t("page.dashboard.revenuSource.title")}
            </h4>
            <div>
              <div id="donut-chart" className="apex-charts">
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="donut"
                  height={262}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

RevenuSource.propTypes = {
  data: PropTypes.any,
};

export default RevenuSource;
