import React from "react";
import MainWrapper from "layout-pages/common/MainWrapper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";
import { TableHead, TableBody } from "layout-pages/common/Table";
import { truncateString } from "utils/helpers/utils";
import CustomCard from "layout-pages/common/CustomCard";
// import { QUERY_CATEGORIES } from "utils/constants/react-query";
// import { useListQuery } from "utils/hooks/useCrudQuery";
// import HocApiData from "utils/hoc/HocApiData";

const tableHead = [
  "Date",
  "Commande_id",
  "Client",
  "Total",
  "Status",
  "Action",
];

function BalanceHistory() {
  // const {
  //   isLoading,
  //   isError,
  //   refetch,
  //   data: payload,
  // } = useListQuery({
  //   url: "categories",
  //   key: QUERY_CATEGORIES,
  // });

  const stats = [
    {
      title: "Solde disponible",
      iconClass: "bx-copy-alt",
      description: "0 MRU",
      color: "primary",
    },

    {
      title: "Solde suspendu",
      iconClass: "bx-purchase-tag-alt",
      description: "0 MRU",
      color: "warning",
    },
    {
      title: "Solde a remboursé",
      iconClass: "bx-archive-in",
      description: "0 MRU",
      color: "danger",
    },
  ];

  return (
    <>
      <MainWrapper title={"Soldes"} heading={"Soldes"}>
        {/* <HocApiData
          loading={false}
          error={false}
          isEmpty={true}
          refetch={() => null}
        > */}
        <Row>
          {/* Dashboard */}
          {stats.map((st, key) => (
            <Col md="4" key={"_col_" + key}>
              <CustomCard
                title={st.title}
                description={st.description}
                iconClass={st.iconClass}
                color={st.color}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col lg="12">
            <div className="table-responsive">
              <Table className="project-list-table table-nowrap align-middle table-borderless mb-0">
                <TableHead cols={tableHead} />
                <tbody>
                  <tr>
                    <td colSpan={tableHead.length}>
                      <p className="text-primary my-4 font-size-16">
                        Aucune données à afficher
                      </p>
                    </td>
                  </tr>
                </tbody>
                {/* <TableBody data={[]} TableRow={TableRow} /> */}
              </Table>
            </div>
          </Col>
        </Row>
        {/* </HocApiData> */}
      </MainWrapper>
    </>
  );
}

function TableRow({ item }) {
  return (
    <tr>
      <td style={{ width: "100px" }}>
        <img
          src={item.photo}
          alt="categorie"
          className="avatar-md h-auto d-block rounded"
        />
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">{item.name_fr}</p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {truncateString(item.desc_fr, 25)}
        </p>
      </td>

      <td>
        <Badge
          className={
            item.visible
              ? "font-size-13 badge-soft-success"
              : "font-size-13 badge-soft-danger"
          }
          color={item.visible ? "success" : "danger"}
          pill
        >
          {item.visible ? "Publié" : "Non publié"}
        </Badge>
      </td>
      <td>
        <UncontrolledDropdown style={{ cursor: "pointer" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#">
              <i className="mdi mdi-eye font-size-16 text-primary me-2" />
              Details
            </DropdownItem>
            <DropdownItem href="#">
              <i className="mdi mdi-pencil font-size-16 text-success me-2" />
              Modifier
            </DropdownItem>
            <DropdownItem href="#">
              <i className="mdi mdi-trash-can font-size-16 text-danger me-2" />
              Supprimer
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  item: PropTypes.object,
};

export default withTranslation()(BalanceHistory);
