import React, { useEffect } from "react";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "reactstrap";
import { CustomTextInput } from "../../../../common/GenericInputForm";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { showAlertMsg } from "../../../../../utils/hoc/Alerts";
import { UPDATE_PROFIL } from "store/auth/login/actionTypes";
import { asyncPost } from "utils/helpers/api_helper";
import { useTranslation } from "react-i18next";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    first_name: Yup.string()
      .required("Nom est obligatoire !")
      .max(50, "Nombre de caractères 50 limite dépassé !"),
    last_name: Yup.string()
      .required("Prénom est obligatoire !")
      .max(50, "Nombre de caractères 50 limite dépassé !"),
    username: Yup.string()
      .required("Nom utilisateur est obligatoire !")
      .max(50, "Nombre de caractères 50 limite dépassé !"),
    email: Yup.string()
      .email("Adresse email invalide !")
      .required("Adresse email obligatoire !")
      .max(50, "Nombre de caractères 50 limite dépassé !"),
    phone_number: Yup.number("Format du numero de telephone invalid")
      .required("Le numero de telephone est obligatoire !")
      .min(20000000, "Le numero de telephone invalid  !")
      .max(99999999, "Le numero de telephone invalid  !"),
    nni: Yup.string()
      .required("NNI est obligatoire !")
      .max(20, "Nombre de caractères 20 limite dépassé !"),
  }),
  mapPropsToValues: props => ({
    first_name: props.editData?.first_name || "",
    last_name: props.editData?.last_name || "",
    username: props.editData?.username || "",
    email: props.editData?.email || "",
    phone_number: props.editData?.phone_number || "",
    nni: props.editData?.nni || "",

    /* upload files */
    avatar: "",
    card_indentity_recto: "",
    card_indentity_verso: "",
    passport_photo: "",
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const payload = {
      ...values,
    };

    /* form data */
    const formData = new FormData();
    formData.append("first_name", payload.first_name);
    formData.append("last_name", payload.last_name);
    formData.append("username", payload.username);
    formData.append("email", payload.email);
    formData.append("phone_number", payload.phone_number);
    formData.append("nni", payload.nni);
    if (payload.avatar && payload.avatar !== "") {
      formData.append("avatar", payload.avatar);
    }
    formData.append("currency", "Mru");

    //
    formData.append("card_indentity_recto", payload.card_indentity_recto);
    formData.append("card_indentity_verso", payload.card_indentity_verso);
    formData.append("passport_photo", payload.passport_photo);

    //
    asyncPost("users/info", formData, {
      "Content-Type": "multipart/form-data",
    }).then(res => {
      if (res?.data?.data) {
        setSubmitting(false);
        props.dispatch({
          type: UPDATE_PROFIL,
          payload: res?.data?.data,
        });

        props.setFileUpload(null);
        showAlertMsg("success", props.t("page.common.toaster.success"));
      } else {
        props.setFileUpload(null);
        showAlertMsg("danger", props.t("page.common.toaster.error"));
        setSubmitting(false);
      }
    });
  },
  displayName: "MyForm",
});

const MyForm = props => {
  useEffect(() => {
    props.setFieldValue("avatar", props.fileUpload);
  }, [props.fileUpload]);

  return (
    <Form onSubmit={props.handleSubmit} className="px-sm-4 py-sm-4 px-1 py-1">
      <Row>
        <Col md={12} lg={6}>
          <CustomTextInput
            type="text"
            className="form-control"
            name="first_name"
            label={props.t("forms.input.firstName.label")}
            required
          />
          <CustomTextInput
            type="text"
            className="form-control"
            name="last_name"
            label={props.t("forms.input.lastName.label")}
            required
          />
          <CustomTextInput
            type="text"
            className="form-control"
            name="username"
            label={props.t("forms.input.username.label")}
            required
            disabled
          />
        </Col>
        <Col md={12} lg={6}>
          <CustomTextInput
            type="text"
            className="form-control"
            name="email"
            label={props.t("forms.input.email.label")}
            required
            disabled
          />
          <CustomTextInput
            type="number"
            className="form-control"
            name="phone_number"
            label={props.t("forms.input.phone.label")}
            required
            disabled
          />
          <CustomTextInput
            type="number"
            className="form-control"
            name="nni"
            label={props.t("forms.input.nni.label")}
            required
            disabled
          />
        </Col>
      </Row>

      {props.isSubmitting ? (
        <Button
          color="primary"
          disabled={props.isSubmitting}
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          {props.t("page.common.spinner.onLoadingLite")}
        </Button>
      ) : (
        <Button
          color="primary"
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          {props.t("page.common.btn.saveUpdates")}
        </Button>
      )}
    </Form>
  );
};

MyForm.propTypes = {
  handleSubmit: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  errors: PropTypes.any,
  isSubmitting: PropTypes.any,
  fileUpload: PropTypes.any,
  dispatch: PropTypes.any,
  t: PropTypes.any,
};

const MyEnhancedForm = formikEnhancer(MyForm);

const FormEditProfile = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return <MyEnhancedForm t={t} {...props} dispatch={dispatch} />;
};

export default FormEditProfile;
