import React from "react";
import MainWrapper from "layout-pages/common/MainWrapper";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";
import { TableHead, TableBody } from "layout-pages/common/Table";
import { truncateString } from "utils/helpers/utils";
import { QUERY_CATEGORIES } from "utils/constants/react-query";
import { useListQuery } from "utils/hooks/useCrudQuery";
import HocApiData from "utils/hoc/HocApiData";

function CategorieHistory() {
  const { t } = useTranslation();
  const tableHead = [
    "#",
    t("page.common.tableHeading.name"),
    t("page.common.tableHeading.desc"),
    t("page.common.tableHeading.type"),
    t("page.common.tableHeading.visiblity"),
    t("page.common.tableHeading.actions"),
  ];

  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useListQuery({
    url: "categories",
    key: QUERY_CATEGORIES,
  });

  return (
    <>
      <MainWrapper
        title={t("page.categories.history.title")}
        heading={t("page.categories.history.heading")}
      >
        <HocApiData
          loading={isLoading}
          error={isError}
          isEmpty={payload?.data?.length === 0}
          refetch={refetch}
        >
          <Row>
            <Col lg="12">
              <div className="table-responsive">
                <Table className="project-list-table table-nowrap align-middle table-borderless mb-0">
                  <TableHead cols={tableHead} />
                  <TableBody data={payload?.data || []} TableRow={TableRow} />
                </Table>
              </div>
            </Col>
          </Row>
        </HocApiData>
      </MainWrapper>
    </>
  );
}

function TableRow({ item }) {
  const { t } = useTranslation();
  return (
    <tr>
      <td style={{ width: "100px" }}>
        <img
          src={item.photo}
          alt="categorie"
          className="avatar-md h-auto d-block rounded"
        />
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">{item.name_fr}</p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {truncateString(item.desc_fr, 25)}
        </p>
      </td>
      <td>
        <Badge
          className={
            item.is_for_shop
              ? "font-size-13 badge-soft-success"
              : "font-size-13 badge-soft-danger"
          }
          color={item.is_for_shop ? "success" : "danger"}
          pill
        >
          {item.is_for_shop
            ? t("page.categories.history.isShop")
            : t("page.categories.history.isNotShop")}
        </Badge>
      </td>
      <td>
        <Badge
          className={
            item.visible
              ? "font-size-13 badge-soft-success"
              : "font-size-13 badge-soft-danger"
          }
          color={item.visible ? "success" : "danger"}
          pill
        >
          {item.visible
            ? t("page.categories.history.isPublished")
            : t("page.categories.history.isNotPublished")}
        </Badge>
      </td>
      <td>
        <UncontrolledDropdown style={{ cursor: "pointer" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#">
              <i className="mdi mdi-eye font-size-16 text-primary me-2" />
              {t("page.common.btn.details")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  item: PropTypes.object,
};

export default CategorieHistory;
