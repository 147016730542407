import React from "react";
import { Card } from "reactstrap";
import MainWrapper from "layout-pages/common/MainWrapper";
import { useSelector } from "react-redux";
import { useListQuery } from "utils/hooks/useCrudQuery";
import { QUERY_SHOPS } from "utils/constants/react-query";
import HocApiData from "utils/hoc/HocApiData";
import FormEditShop from "./components/FormEditShop";

export default function EditShop() {
  const user = useSelector(state => state.Login.user);

  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useListQuery({
    url: "shops",
    key: QUERY_SHOPS,
  });

  //show Modal
  const last_index = payload?.data?.length - 1;
  return (
    <>
      <MainWrapper
        title={"Modifier votre boutique"}
        heading={"Modifier votre boutique"}
      >
        <HocApiData
          loading={isLoading}
          error={isError}
          isEmpty={payload?.data?.length === 0}
          refetch={refetch}
        >
          <Card className="p-3">
            <FormEditShop shop={payload?.data[last_index]} />
          </Card>
        </HocApiData>
      </MainWrapper>
    </>
  );
}
