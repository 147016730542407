import React from 'react';
import { FormGroup, Input, Label } from "reactstrap";
import { useField } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CustomSelect = ({ ...props }) => {
  const [field, meta] = useField(props);
  const { t } = useTranslation();
  return (
    <>
    <FormGroup className="mb-3">
    {props.label && (
          <Label htmlFor={props.id || props.name}>
            {t(props.label)} {props?.required && "*"}
          </Label>
        )}
    <select
        className="form-control bg-dark text-white"
        {...field} 
        {...props}
      >
           {props.options.map((option, index) => (
          <option key={index} selected={index == 0} value={option.id}>
            {option.name}
          </option>
        ))}

      </select>

    {meta.error && meta.touched && (
          <div style={{ color: "red", marginTop: ".5rem" }}>{meta.error}</div>
        )}
    
    </FormGroup>
    </>
  );
  return (
    <>
    <FormGroup className="mb-3">
    <div className={`custom-select-wrapper ${props.className}`}>
      {label && (
        <label htmlFor={props.name} className="form-label">
          {props.label}
          {props?.required && <span className="required">*</span>}
        </label>
      )}
      <select
        className="form-control"
        name={props.name}
        id={props.name}
        required={props?.required}
        disabled={props?.disabled}
        {...field} 
        {...props}
      >
        {options.map((option, index) => (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
    
    </FormGroup>
    </>
  );
};


CustomSelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.any, // optional
  disabled: PropTypes.any, // optional
  options: PropTypes.array, // optional
};

export default CustomSelect;
