import { IS_LOADING, HAS_ERROR, FETCH_CITIES } from "./actionTypes";
import { get } from "../../../utils/helpers/api_helper";

export const getCountries = () => async dispatch => {
  dispatch({
    type: IS_LOADING,
    payloadType: FETCH_CITIES,
  });

  try {
    const response = await get("cities");
    dispatch({
      type: FETCH_CITIES,
      payload: response?.data,
    });
  } catch (err) {
    dispatch({
      type: HAS_ERROR,
      payloadType: FETCH_CITIES,
      payload: err,
    });
  }
};
