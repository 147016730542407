import React, { useState } from "react";
import MainWrapper from "layout-pages/common/MainWrapper";
import PropTypes from "prop-types";
import { useTranslation, withTranslation } from "react-i18next";
import { Col, Row, Table, Badge, Button } from "reactstrap";
import { TableHead, TableBody } from "layout-pages/common/Table";
import {
  formatApiDate,
  numberWithThousandSeparator,
} from "utils/helpers/utils";
import { QUERY_LITIGES } from "utils/constants/react-query";
import { useListQuery } from "utils/hooks/useCrudQuery";
import HocApiData from "utils/hoc/HocApiData";
import {
  LITIGES_STATUS_MAPPER,
  LITIGE_CLOSED,
  TRANSACTION_TYPE_MAPPER,
} from "utils/constants/enum";
import CustomModal from "layout-pages/common/CustomModal";
import ChatBox from "./ChatBox";

function LitigeHistory() {
  const { t } = useTranslation();
  const tableHead = [
    t("page.common.tableHeading.date"),
    t("page.common.tableHeading.customer"),
    t("page.common.tableHeading.amount"),
    t("page.common.tableHeading.type"),
    t("page.common.tableHeading.status"),
    t("page.common.tableHeading.actions"),
  ];

  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useListQuery({
    url: "users/litiges",
    key: QUERY_LITIGES,
  });

  // promote modal stuff
  const [showChatsModal, setShowChatsModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const handleChatsModal = (modal, current = null) => {
    setCurrentItem(current);
    setShowChatsModal(modal);
  };

  // unsed !!
  // const litiges = useMemo(() => {
  //   if (payload?.opened && payload?.justice) {
  //     return [...payload.opened, ...payload.justice];
  //   }
  //   return [];
  // }, [payload]);

  return (
    <>
      {/* promote modal */}
      <CustomModal
        title={t("page.litiges.history.chatModalTitle")}
        isOpen={showChatsModal}
        toggle={() => handleChatsModal(false)}
        size="xl"
        showFooter={false}
        fullscreen={true}
        content={<ChatBox current={currentItem} />}
      />
      {/*   */}
      <MainWrapper
        title={t("page.litiges.history.title")}
        heading={t("page.litiges.history.heading")}
      >
        <HocApiData
          loading={isLoading}
          error={isError}
          isEmpty={payload?.length === 0}
          refetch={refetch}
        >
          <Row>
            <Col lg="12">
              <div className="table-responsive">
                <Table className="project-list-table table-nowrap align-middle table-borderless mb-0">
                  <TableHead cols={tableHead} />
                  <TableBody
                    data={payload || []}
                    TableRow={TableRow}
                    handleChatsModal={handleChatsModal}
                  />
                </Table>
              </div>
            </Col>
          </Row>
        </HocApiData>
      </MainWrapper>
    </>
  );
}

function TableRow({ item, handleChatsModal }) {
  // const [status_, setstatus_] = useState(
  //   litiges_status[Math.floor(Math.random() * litiges_status.length)]
  // );
  const { t } = useTranslation();
  return (
    <tr>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {formatApiDate(item.created_at)}
        </p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {`${item?.order.first_name} ${item?.order.last_name}`}
        </p>
      </td>

      <td>
        <h5 className="text-truncate font-size-14 mb-0">
          {numberWithThousandSeparator(item.transaction_amount)}{" "}
          <span className="ml-2">MRU</span>
        </h5>
      </td>

      <td>
        <Badge
          className={
            "font-size-14 badge-soft-" +
            TRANSACTION_TYPE_MAPPER[item.transaction_type]?.backgroundColor
          }
          color={
            TRANSACTION_TYPE_MAPPER[item.transaction_type]?.backgroundColor
          }
          pill
        >
          {t(TRANSACTION_TYPE_MAPPER[item.transaction_type]?.label)}
        </Badge>
      </td>

      <td>
        <Badge
          className={
            "font-size-13 badge-soft-" +
            LITIGES_STATUS_MAPPER[item.status]?.backgroundColor
          }
          color={LITIGES_STATUS_MAPPER[item.status]?.backgroundColor}
          pill
        >
          {t(LITIGES_STATUS_MAPPER[item.status]?.label)}
        </Badge>
      </td>
      <td>
        {item.status !== LITIGE_CLOSED ? (
          <Button
            color="primary"
            className="text-white px-2 py-1"
            onClick={() => handleChatsModal(true, item)}
          >
            <i className="mdi mdi-chat font-size-16 text-primary text-white" />
          </Button>
        ) : (
          "--"
        )}
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  item: PropTypes.object,
  handleChatsModal: PropTypes.func,
};

export default withTranslation()(LitigeHistory);
