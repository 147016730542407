import React from "react";
import CustomerHistory from "./components/CustomerHistory";

export default function Customer() {
  return (
    <>
      <CustomerHistory />
    </>
  );
}
