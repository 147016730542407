import React from "react";
import ProductHistory from "./components/ProductHistory";

export default function Product() {
  return (
    <>
      <ProductHistory />
    </>
  );
}
