import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatApiDate, isValidHttpUrl } from "utils/helpers/utils";

class Receiver extends Component {
  render() {
    const message = this.props.message;
    const file = this.props.message.file?.url;
    const cdn = process.env.REACT_APP_CDN;
    // file && console.log(isValidHttpUrl(file) ? file : cdn + file);
    return (
      <>
        {file && (
          <li>
            <img
              className="rounded me-2 mb-2"
              alt="Img"
              width="200"
              src={isValidHttpUrl(file) ? file : cdn + file}
            />
          </li>
        )}

        <li>
          <div className="conversation-list">
            <div className="ctext-wrap">
              <p>{message["msg"]}</p>
              <p className="chat-time mb-0">
                <i className="bx bx-time-five align-middle me-1" />{" "}
                {formatApiDate(message["updated_at"]) || "--"}
              </p>
            </div>
          </div>
        </li>
      </>
    );
  }
}

Receiver.propTypes = {
  message: PropTypes.object,
  file: PropTypes.object,
};

export default Receiver;
