import React from "react";
import CategorieHistory from "./components/CategorieHistory";

export default function Categorie() {
  return (
    <>
      <CategorieHistory />
    </>
  );
}
