import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

/* Admin pages */
// Dashboard
import AdminDashboard from "../pages/Dashboard/BO/Dashboard";

/* Customer pages */
// Dashboard
import CustomerDashboard from "../pages/Dashboard/FO/Dashboard";
import CustomerProfile from "../pages/Profile/FO/Profile";
import CustomerAddShop from "../pages/Shops/FO/AddShop";
import CustomerEditShop from "../pages/Shops/FO/EditShop";
import CustomerShopDetail from "../pages/Shops/FO/ShopDetail";
import CustomerProduct from "../pages/Products/FO/Product";
import CustomerCategorie from "../pages/Categories/FO/Categorie";
import CustomerBalance from "../pages/Balance/FO/Balance";
import CustomerTransaction from "../pages/Transactions/FO/Transaction";
import CustomerClient from "../pages/Customers/FO/Customer";
import CustomerLitige from "../pages/Litiges/FO/Litige";
import CustomerBank from "../pages/Bank/FO/Bank"
import MailSend from "layout-pages/pages/Authentication/MailSend";
import MailResend from "layout-pages/pages/Authentication/MailResend";
import MailVerify from "layout-pages/pages/Authentication/MailVerify";

const customerProtectedRoutes = [
  {
    path: "/dashboard",
    component: CustomerDashboard,
    isVerifiedAccountGuard: false,
  },

  {
    path: "/profile",
    component: CustomerProfile,
    isVerifiedAccountGuard: true,
  },

  {
    path: "/bank",
    component: CustomerBank,
    isVerifiedAccountGuard: true,
  },

  // ** hidden temporarily
   {
     path: "/add/shop",
     component: CustomerAddShop,
     isVerifiedAccountGuard: true,
   },
   {
     path: "/edit/shop",
     component: CustomerEditShop,
     isVerifiedAccountGuard: true,
   },
   {
     path: "/shops",
     component: CustomerShopDetail,
     isVerifiedAccountGuard: true,
   },

   {
     path: "/products",
     component: CustomerProduct,
     isVerifiedAccountGuard: true,
   },

   {
     path: "/categories",
     component: CustomerCategorie,
     isVerifiedAccountGuard: true,
   },

   { 
    path: "/balance", 
    component: CustomerBalance ,
    isVerifiedAccountGuard: true,},

  {
    path: "/transactions",
    component: CustomerTransaction,
    isVerifiedAccountGuard: true,
  },

  {
    path: "/customers",
    component: CustomerClient,
    isVerifiedAccountGuard: true,
  },

  {
    path: "/conflits",
    component: CustomerLitige,
    isVerifiedAccountGuard: true,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const adminProtectedRoutes = [
  {
    path: "/dashboard",
    component: AdminDashboard,
    isVerifiedAccountGuard: false,
  },

  // >> Cards
  // { path: "/cards", component: Cards },
  // { path: "/cards/:id", component: CardDetails },
  // { path: "/card-add", component: AddCard },
  // { path: "/card-edit", component: EditCard },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/mail/send", component: MailSend },
  { path: "/mail/resend", component: MailResend },
  { path: "/verify/:token", component: MailVerify },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:token", component: ResetPassword },
];

export { customerProtectedRoutes, adminProtectedRoutes, publicRoutes };
