import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const chartOptions = {
  chart: {
    toolbar: "false",
    dropShadow: {
      enabled: !0,
      color: "#000",
      top: 18,
      left: 7,
      blur: 8,
      opacity: 0.2,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  colors: ["#556ee6"],
  stroke: {
    curve: "smooth",
    width: 3,
  },
};

function RevenueChart({ data }) {
  const { t } = useTranslation();
  const chartData = [
    {
      name: t("page.dashboard.revenueChart.title"),
      data: data?.data || [],
    },
  ];

  return (
    <>
      <Col xl="8">
        <Card>
          <CardBody>
            <div className="clearfix">
              {/* <div className="float-end">
                  <div className="input-group input-group-sm">
                    <select
                      value={seletedMonth}
                      onChange={e => {
                        this.setState({
                          ...this.state,
                          seletedMonth: e.target.value,
                        });
                        this.props.onGetEarningChartsData(e.target.value);
                      }}
                      className="form-select form-select-sm"
                    >
                      <option value="jan">Jan</option>
                      <option value="dec">Dec</option>
                      <option value="nov">Nov</option>
                      <option value="oct">Oct</option>
                    </select>
                    <label className="input-group-text">Month</label>
                  </div>
                </div> */}
              <h4 className="card-title mb-4">
                {t("page.dashboard.revenueChart.title")}
              </h4>
            </div>

            <Row>
              <Col lg="12">
                <div id="line-chart" className="apex-charts" dir="ltr">
                  <ReactApexChart
                    series={chartData}
                    options={{
                      ...chartOptions,
                      xaxis: { categories: data?.labels },
                    }}
                    type="line"
                    height={320}
                    className="apex-charts"
                  />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

RevenueChart.propTypes = {
  data: PropTypes.any,
};

export default RevenueChart;
