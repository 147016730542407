import React from "react";
import MainWrapper from "layout-pages/common/MainWrapper";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Badge,
} from "reactstrap";
import { TableHead } from "layout-pages/common/Table";
import { truncateString } from "utils/helpers/utils";
// import CustomCard from "layout-pages/common/CustomCard";
// import { QUERY_CATEGORIES } from "utils/constants/react-query";
// import { useListQuery } from "utils/hooks/useCrudQuery";
// import HocApiData from "utils/hoc/HocApiData";

function CustomerHistory() {
  const { t } = useTranslation();
  const tableHead = [
    "#",
    t("page.common.tableHeading.fullName"),
    t("page.common.tableHeading.phone"),
    t("page.common.tableHeading.email"),
    t("page.common.tableHeading.address"),
    t("page.common.tableHeading.status"),
    t("page.common.tableHeading.actions"),
  ];

  // const {
  //   isLoading,
  //   isError,
  //   refetch,
  //   data: payload,
  // } = useListQuery({
  //   url: "categories",
  //   key: QUERY_CATEGORIES,
  // });

  return (
    <>
      <MainWrapper
        title={t("page.customers.history.title")}
        heading={t("page.customers.history.heading")}
      >
        {/* <HocApiData
          loading={false}
          error={false}
          isEmpty={true}
          refetch={() => null}
        > */}

        <Row>
          <Col lg="12">
            <div className="table-responsive">
              <Table className="project-list-table table-nowrap align-middle table-borderless mb-0">
                <TableHead cols={tableHead} />
                <tbody>
                  <tr>
                    <td colSpan={tableHead.length}>
                      <p className="text-primary my-4 font-size-16">
                        {t("page.common.hocApiData.emptyData")}
                      </p>
                    </td>
                  </tr>
                </tbody>
                {/* <TableBody data={[]} TableRow={TableRow} /> */}
              </Table>
            </div>
          </Col>
        </Row>
        {/* </HocApiData> */}
      </MainWrapper>
    </>
  );
}

function TableRow({ item }) {
  return (
    <tr>
      <td style={{ width: "100px" }}>
        <img
          src={item.photo}
          alt="customer"
          className="avatar-md h-auto d-block rounded"
        />
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">{item.name_fr}</p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {truncateString(item.desc_fr, 25)}
        </p>
      </td>

      <td>
        <Badge
          className={
            item.visible
              ? "font-size-13 badge-soft-success"
              : "font-size-13 badge-soft-danger"
          }
          color={item.visible ? "success" : "danger"}
          pill
        >
          {item.visible ? "Publié" : "Non publié"}
        </Badge>
      </td>
      <td>
        <UncontrolledDropdown style={{ cursor: "pointer" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#">
              <i className="mdi mdi-eye font-size-16 text-primary me-2" />
              Details
            </DropdownItem>
            <DropdownItem href="#">
              <i className="mdi mdi-pencil font-size-16 text-success me-2" />
              Modifier
            </DropdownItem>
            <DropdownItem href="#">
              <i className="mdi mdi-trash-can font-size-16 text-danger me-2" />
              Supprimer
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  item: PropTypes.object,
};

export default CustomerHistory;
