import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";

const Dashboard = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Tableau de bord | Sa7elpay</title>
      </MetaTags>
      <Container fluid>
        <h2>Admin Dashbord</h2>
      </Container>
    </div>
  );
};

export default withTranslation()(Dashboard);
