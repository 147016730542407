import MainWrapper from "layout-pages/common/MainWrapper";
import PropTypes from "prop-types";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Badge,
  Button,
} from "reactstrap";
import { TableHead, TableBody } from "layout-pages/common/Table";
import {
  numberWithThousandSeparator,
  truncateString,
} from "utils/helpers/utils";
import CustomCard from "layout-pages/common/CustomCard";
import CustomModal from "layout-pages/common/CustomModal";
import { QUERY_PRODUCTS } from "utils/constants/react-query";
import { reactQueryCacheCleaner, useListQuery } from "utils/hooks/useCrudQuery";
import HocApiData from "utils/hoc/HocApiData";
import img_404 from "../../../../../assets/images/placeholder.png";
import { asyncDelete } from "utils/helpers/api_helper";
import { useQueryClient } from "react-query";
import FormPromoteProduct from "./FormPromoteProduct";
import FormEditProduct from "./FormEditProduct";
import FormAddProduct from "./FormAddProduct";
import FormShowProduct from "./FormShowProduct";

const tableHead = [
  "#",
  "Nom",
  "Description",
  "Prix",
  "Disponible",
  "Visible",
  "Promotion",
  "Action",
];

const ProductHistory = () => {
  const [currentItem, setCurrentItem] = useState(null);

  // add modal stuff
  const [showAddModal, setShowAddModal] = useState(false);

  // delete modal stuff
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteModal = (modal, current = null) => {
    setCurrentItem(current);
    setShowDeleteModal(modal);
  };
  const deleteProduct = () => {
    setDeleteIsLoading(true);
    asyncDelete(`products/${currentItem?.id}`).then(res => {
      reactQueryCacheCleaner({
        queryClient,
        resetKeys: [QUERY_PRODUCTS],
        invalidateKeys: [],
      });
      setDeleteIsLoading(false);
      handleDeleteModal(false);
    });
  };

  // promote modal stuff
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const handlePromoteModal = (modal, current = null) => {
    setCurrentItem(current);
    setShowPromoteModal(modal);
  };

  // edit modal stuff
  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditModal = (modal, current = null) => {
    setCurrentItem(current);
    setShowEditModal(modal);
  };

  // Aperçu modal stuff
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = (modal, current = null) => {
    setCurrentItem(current);
    setShowModal(modal);
  };

  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useListQuery({
    url: "products",
    key: QUERY_PRODUCTS,
  });

  const stats = [
    {
      title: "Total Produits",
      iconClass: "bx-copy-alt",
      description: payload?.data?.length,
      color: "primary",
    },

    {
      title: "Produits Non Publiés",
      iconClass: "bx-purchase-tag-alt",
      description: payload?.data?.filter(item => !item.visible).length,
      color: "warning",
    },
    {
      title: "Produits Hors Stock",
      iconClass: "bx-archive-in",
      description: payload?.data?.filter(item => !item.stock).length,
      color: "danger",
    },
  ];

  const queryClient = useQueryClient();
  return (
    <>
      {/* add modal */}
      <CustomModal
        title={"Ajouter un nouveau produit"}
        isOpen={showAddModal}
        toggle={() => setShowAddModal(false)}
        size="lg"
        showFooter={false}
        content={
          <FormAddProduct
            fromShopPage={false}
            closeModal={() => setShowAddModal(false)}
          />
        }
      />

      {/* promote modal */}
      <CustomModal
        title={"Promouvoir un produit"}
        isOpen={showPromoteModal}
        toggle={() => handlePromoteModal(false)}
        size="md"
        showFooter={false}
        content={
          <FormPromoteProduct
            closeModal={handlePromoteModal}
            product={currentItem}
          />
        }
      ></CustomModal>
      

      {/* edit modal */}
      <CustomModal
        title={"Modifier un produit"}
        isOpen={showEditModal}
        toggle={() => handleEditModal(false)}
        size="lg"
        showFooter={false}
        content={
          <FormEditProduct closeModal={handleEditModal} product={currentItem} />
        }
      ></CustomModal>

      {/* Display Product modal */}
      <CustomModal
        title={"Aperçu du produit"}
        isOpen={showModal}
        toggle={() => handleShowModal(false)}
        size="lg"
        showFooter={false}
        content={
          <FormShowProduct closeModal={handleShowModal} product={currentItem} />
        }
      ></CustomModal>

      {/* delete modal */}
      <CustomModal
        title={"Supression d'un produit"}
        isOpen={showDeleteModal}
        toggle={() => handleDeleteModal(false)}
        size="md"
        showFooter={false}
        content={
          <>
            <div className="font-size-14">
              Vous confirmer la suppression de ce produit ?
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div>
                  <Button
                    color="danger"
                    className="text-white px-3 py-2 mt-3"
                    disabled={deleteIsLoading}
                  >
                    Annuler
                  </Button>
                </div>
                <div>
                  <Button
                    color="success"
                    className="text-white px-3 py-2 mt-3"
                    disabled={deleteIsLoading}
                    onClick={deleteProduct}
                  >
                    Confirmer
                  </Button>
                </div>
              </div>
            </div>
          </>
        }
      />
      <MainWrapper title={"Mes Produits"} heading={"Mes Produits"}>
        <HocApiData
          loading={isLoading}
          error={isError}
          isEmpty={payload?.data?.length === 0}
          refetch={refetch}
        >
          <Row>
            {/* Dashboard */}
            {stats.map((st, key) => (
              <Col md="4" key={"_col_" + key}>
                <CustomCard
                  title={st.title}
                  description={st.description}
                  iconClass={st.iconClass}
                  color={st.color}
                />
              </Col>
            ))}
          </Row>

          <Row>
            <Col lg="12">
              <div className="d-flex align-items-center justify-content-end mb-2 mt-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setShowAddModal(true)}
                  >
                    <i className="bx bx-plus font-size-18 align-middle me-2"></i>
                    Nouveau Produit
                  </button>
                </div>
              </div>

              <div className="table-responsive">
                <Table className="project-list-table table-nowrap align-middle table-borderless mb-0">
                  <TableHead cols={tableHead} />
                  <TableBody
                    data={payload?.data || []}
                    TableRow={TableRow}
                    handleDeleteModal={handleDeleteModal}
                    handlePromoteModal={handlePromoteModal}
                    handleEditModal={handleEditModal}
                    handleShowModal={handleShowModal}
                  />
                </Table>
              </div>
            </Col>
          </Row>
        </HocApiData>
      </MainWrapper>
    </>
  );
};

function TableRow({
  item,
  handleDeleteModal,
  handlePromoteModal,
  handleEditModal,
  handleShowModal,
}) {
  return (
    <tr>
      <td style={{ width: "100px" }}>
        <img
          src={item.featured_image}
          alt="product"
          className="avatar-md h-auto d-block rounded"
          onError={e => {
            e.target.onerror = null;
            e.target.src = img_404;
            // e.target.style.width = "60px";
          }}
        />
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">{item.title_fr}</p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {truncateString(item.description_fr, 25)}
        </p>
      </td>
      <td>
        <h5 className="text-truncate font-size-14 mb-0">
          {numberWithThousandSeparator(item.price)}{" "}
          <span className="ml-2">MRU</span>
        </h5>
      </td>
      <td>
        {!item.stock && (
          <Badge className="font-size-13 badge-soft-danger" color="danger" pill>
            Non disponible
          </Badge>
        )}

        {item.stock > 0 && (
          <h5 className="text-truncate text-center font-size-14 mb-0">
            {item.stock}
          </h5>
        )}
      </td>
      <td>
        <Badge
          className={
            item.visible
              ? "font-size-13 badge-soft-success"
              : "font-size-13 badge-soft-danger"
          }
          color={item.visible ? "success" : "danger"}
          pill
        >
          {item.visible ? "Publié" : "Non publié"}
        </Badge>
      </td>

      <td>
        <Badge
          className={
            item.advert
              ? item.advert?.status === "inactive"
                ? "font-size-13 badge-soft-warning"
                : "font-size-13 badge-soft-success"
              : "font-size-13 badge-soft-secondary"
          }
          color={
            item.advert
              ? item.advert?.status === "inactive"
                ? "warning"
                : "success"
              : "secondary"
          }
          pill
        >
          {item.advert
            ? item.advert?.status === "inactive"
              ? "En attente"
              : "Active"
            : "Non défini"}
        </Badge>
      </td>
      <td>
        <UncontrolledDropdown style={{ cursor: "pointer" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem href="#"
            onClick={e => {
              e.preventDefault();
              handleShowModal(true, item);
            }}
            >
              <i className="mdi mdi-eye font-size-16 text-primary me-2" />
              Details
            </DropdownItem>
            {/*
            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault();
                handlePromoteModal(true, item);
              }}
            >
              <i className="mdi mdi-arrow-up font-size-16 text-info me-2" />
              Promouvoir
            </DropdownItem>
            */}
            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault();
                handleEditModal(true, item);
              }}
            >
              <i className="mdi mdi-pencil font-size-16 text-success me-2" />
              Modifier
            </DropdownItem>
            <DropdownItem
              href="#"
              onClick={e => {
                e.preventDefault();
                handleDeleteModal(true, item);
              }}
            >
              <i className="mdi mdi-trash-can font-size-16 text-danger me-2" />
              Supprimer
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  item: PropTypes.object,
  handleDeleteModal: PropTypes.func,
  handlePromoteModal: PropTypes.func,
  handleEditModal: PropTypes.func,
  handleShowModal: PropTypes.func,
};

export default withTranslation()(ProductHistory);
