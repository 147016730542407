const DEFAULT_COLOR = "#ffff";

// transactions type
export const TRANSACTION_CASH_IN = "CASH_IN";
export const TRANSACTION_CASH_OUT = "CASH_OUT";
export const TRANSACTION_TRANSFERT = "TRANSFERT";
export const TRANSACTION_PAY = "PAY";

export const TRANSACTION_TYPE_MAPPER = {
  CASH_IN: {
    label: "page.common.enum.transactionStatus.cashin",
    backgroundColor: "primary",
    //color: DEFAULT_COLOR,
  },
  CASH_OUT: {
    label: "page.common.enum.transactionStatus.cashout", //"Cash out",
    backgroundColor: "danger",
    //color: DEFAULT_COLOR,
  },
  TRANSFERT: {
    label: "page.common.enum.transactionStatus.transfert", //"Transfert",
    backgroundColor: "warning",
    //color: DEFAULT_COLOR,
  },
  PAY: {
    label: "page.common.enum.transactionStatus.pay", //"Paiement",
    backgroundColor: "success",
    //color: DEFAULT_COLOR,
  },
};

// orders status
export const ORDER_PENDING = "PENDING";
export const ORDER_DELIVERING = "DELIVERING";
export const ORDER_FINISHED = "FINISHED";
export const ORDER_COMPLETED = "COMPLETED";
export const ORDER_SUSPENDED = "SUSPENDED";
export const ORDER_CANCELLED = "CANCELLED";

export const ORDER_STATUS_MAPPER = {
  PENDING: {
    label: "page.common.enum.orderStatus.pending", //"En attente",
    backgroundColor: "info",
  },
  DELIVERING: {
    label: "page.common.enum.orderStatus.delivering", //"Livraison en cours",
    backgroundColor: "primary",
  },
  FINISHED: {
    label: "page.common.enum.orderStatus.finished", //"Terminé",
    backgroundColor: "success",
  },
  COMPLETED: {
    label: "page.common.enum.orderStatus.done", //"Compléte",
    backgroundColor: "success",
  },
  SUSPENDED: {
    label: "page.common.enum.orderStatus.suspended", //"Bloqué",
    backgroundColor: "warning",
  },
  CANCELLED: {
    label: "page.common.enum.orderStatus.canceled", //"Annulé",
    backgroundColor: "danger",
  },
};

// litiges status
export const LITIGE_OPENED = "OPENED";
export const LITIGE_JUSTICE = "JUSTICE";
export const LITIGE_CLOSED = "CLOSED";

export const LITIGES_STATUS_MAPPER = {
  OPENED: {
    label: "page.common.enum.litigeStatus.opened", //"En cours",
    backgroundColor: "warning",
  },
  JUSTICE: {
    label: "page.common.enum.litigeStatus.justice", //"Justice",
    backgroundColor: "danger",
  },
  CLOSED: {
    label: "page.common.enum.litigeStatus.closed", //"Resolue",
    backgroundColor: "success",
  },
};
