import React, { useEffect } from "react";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "reactstrap";
import { CustomTextInput } from "../../../../common/GenericInputForm";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { showAlertMsg } from "../../../../../utils/hoc/Alerts";
import { VALIDATE_CODE_SMS } from "store/auth/login/actionTypes";
import { asyncPost } from "utils/helpers/api_helper";
import { useTranslation } from "react-i18next";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    amount: Yup.number()
      .min(0,"Le montant doit être positif")
      .required("Le montant est obligatoire !"),

    internet_code: Yup.string()
    .required("code internet est obligatoire !")
    .matches(/^\d{4}$/, "Le code internet doit être un nombre de 4 chiffres"),
    
  }),
  mapPropsToValues: props => ({
    amount: "",
    internet_code: "",
    payWith: props.editData?.account_only?.step_instruct_slug || "",
    account: props.editData?.user_account_number ? ( props.editData?.user_account_number?.substring(0,4)+"....") : "",

  }),


  handleSmsVerficationSubmit: (values, { props, setSubmitting, resetForm }) => {
    const payload = {
      ...values,
    };

  },

  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const payload = {
      ...values,
    };

    /* form data */
    const formData = new FormData();

    formData.append("amount", payload.amount);
    formData.append("internet_code", payload.internet_code);
    formData.append("payWith", payload.payWith);
    //---

    //
    asyncPost("cashout", formData, {
      "Content-Type": "multipart/form-data",
    }).then(res => {

      if (res?.data?.response) {
        //---- 
        setSubmitting(false);
        props.dispatch({
          type: VALIDATE_CODE_SMS,
          payload: res?.data?.response,
        });
        //---- 
        let data = res?.data; 
        data["response"]["cashout"]=payload;
        //res?.data?.response.add = payload;
        //---- 
        props.setSmsCode(data)
        showAlertMsg("success", props.t("page.common.toaster.success"));
      } else {
        showAlertMsg("danger", props.t("page.common.toaster.error"));
        setSubmitting(false);
      }
    });
  },
  displayName: "MyForm",
});

const MyForm = props => {
 

  return (
    <Form onSubmit={props.handleSubmit} className="px-sm-4 py-sm-4 px-1 py-1">
      <Row>
        <Col md={12} lg={6}>
          <CustomTextInput
            type="number"
            className="form-control"
            name="amount"
            label={props.t("page.bank.index.cashoutAmount")}
            required
          />
          <CustomTextInput
            type="password"
            className="form-control"
            name="internet_code"
            label={props.t("page.bank.index.cashoutInternetPsw")}
            required
          />
         
        </Col>

        <Col md={12} lg={6}>
          <CustomTextInput
            type="text"
            className="form-control"
            name="solde"
            label={props.t("page.bank.index.cashoutAmountHint")}
            value={props.solde+" Mru"}
            disabled
          />
          <CustomTextInput
            type="hidden"
            className="form-control"
            name="payWith"
            label=""
            required
            disabled
          />

          <CustomTextInput
            type="text"
            className="form-control"
            name="account"
            label={props.t("page.bank.index.accountTitle")}
            disabled
            
          />
          
        </Col>
      </Row>

      {props.isSubmitting ? (
        <Button
          color="primary"
          disabled={props.isSubmitting}
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          {props.t("page.common.spinner.onLoadingLite")}
        </Button>
      ) : (
        <Button
          color="success"
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          {props.t("page.bank.index.cashoutBut")}
        </Button>
      )}
    </Form>
  );
};

MyForm.propTypes = {
  handleSubmit: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  errors: PropTypes.any,
  isSubmitting: PropTypes.any,
  setSmsCode: PropTypes.func,
  solde: PropTypes.number,
  editData: PropTypes.object,
  dispatch: PropTypes.any,
  t: PropTypes.any,
};

const MyEnhancedForm = formikEnhancer(MyForm);

const FormAddCashout = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return <MyEnhancedForm t={t} {...props} dispatch={dispatch} />;
};

export default FormAddCashout;
