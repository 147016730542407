import React, { useState, useEffect } from "react";
import MainWrapper from "layout-pages/common/MainWrapper";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useSelector } from "react-redux";
import avatar from "../../../../assets/images/placeholder.png";
import qrcode_placeholder from "../../../../assets/images/qrcode_placeholder.png";
import FormEditProfile from "./components/FormEditProfile";
import FormEditPswd from "./components/FormEditPswd";
import { useTranslation } from "react-i18next";
import { VALIDATE_CODE_SMS } from "store/auth/login/actionTypes";
import { QUERY_BANKS } from "utils/constants/react-query";
import { useListQuery } from "utils/hooks/useCrudQuery";
import HocApiData from "utils/hoc/HocApiData";
import FormAddBankAccount from "./components/FormAddBankAccount";
import FormAddCashout from "./components/FormAddCashout";
import FormSmsValidation from "./components/FormSmsValidation";


export default function Bank() {
  const { t } = useTranslation();
  const user = useSelector(state => state.Login.user);
  const smsCodeValidation = useSelector(state => state.Login.smsCodeValidation);

  const [fileUpload, setFileUpload] = useState(null);
  const [preview, setPreview] = useState();
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [shouldReload, setShouldReload] = useState(false); // State to control re-render
  const [smsCodeData, setSmsCodeData] = useState(null); // State to control re-render


  const setSmsCode = (data)=>{
    //console.log('received data')
    //console.log(data)
    setSmsCodeData(data); // Store the received data
    setShouldReload(prev => !prev); // Toggle the state to trigger re-render
  }
  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useListQuery({
    url: "/userbank",
    key: QUERY_BANKS,
  });

  const hiddenFileInputProfil = React.useRef(null);
  const handleProfilClick = _ => {
    hiddenFileInputProfil.current.click();
  };


  const handleProfilChange = event => {
    if (!event.target.files || event.target.files.length === 0) {
      setFileUpload(null);
      return;
    }
    setFileUpload(event.target.files[0]);
  };


  useEffect(() => {
    if (smsCodeValidation) {
      //console.log("SMS Code validated:", smsCodeValidation);
      // Perform any additional actions here based on the SMS code validation
    }
  }, [smsCodeValidation]);

  // Re-fetch data when shouldReload changes
  useEffect(() => {
    refetch();
  }, [shouldReload, refetch]);

  useEffect(() => {
    if (!fileUpload) {
      setPreview(undefined);
      return;
    }


    const objectUrl = URL.createObjectURL(fileUpload);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [fileUpload]);
  console.log(payload)
  return (
    <>
      <MainWrapper
        title={t("page.bank.index.accountTitle")}
        heading={t("page.bank.index.accountTitle")}
      >
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                { payload?.account ? (
                <HocApiData loading={isLoading} error={isError} refetch={refetch}>
                  <div className="d-flex">
                    <div className="me-3 d-flex flex-column align-items-center justify-content-center">
                      <div>
                        <img
                          src={isError ? preview : payload?.bank_url}
                          onError={e => {
                            e.target.onerror = null;
                            e.target.src = avatar;
                          }}
                          alt="Logo de mon Compte profil"
                          className="avatar-lg rounded-circle img-thumbnail"
                        />

                      </div>
                      
                    </div>

                    <div className="align-self-center flex-1">
                      {user && (
                        <div className="text-muted">
                          <h5>{`${payload?.account?.account_only?.name} `}</h5>
                          <p className="mb-0 " style={{ color: payload?.account?.state == "ACTIVE" ? "green": "red" }} >{`${ t( payload?.account?.is_cashin == 0? "page.bank.index.stateWaitingTrans" : ( payload?.account?.state == "ACTIVE" ? "page.bank.index.stateValidated" :"page.bank.index.stateValidating")) }`}</p>
                        </div>
                      )}
                    </div>

                    <div style={payload?.account?.is_cashin ? {display : "none"} :{ marginLeft: "auto" }}>
                      <a
                        href={payload?.firstCashIn}
                        alt="Add_first_cashIn"
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="btn btn-success mt-4"
                      >
                        <div className="text-center">
                        {payload?.account?.is_cashin == 1}
                          {t("page.bank.index.stateWaitingTransBut" )}
                        </div>
                      </a>
                    </div>
                    
                  </div>
                </HocApiData>
                 ) : (
                  <div className="no-account-message">
                  {/* Message or alternate content when there is no account */}
                  <h5>{t('page.bank.index.noAccountSet')}</h5>
                </div>

                 )}

                 { payload?.account?.is_cashin ? ( 
                  
                    <div className={payload?.account?.is_cashin ? "d-none": "mt-2 text-center small alert alert-info"}>
                      {t("page.bank.index.accountHint")}
                    </div>
                    ):
                    (<p> </p>)
}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card className={payload?.account?.state == 'CONFIRMING' ? "d-none" :"" }>
          <CardBody>
            <h5>{t(payload?.account ? "page.bank.index.cashoutSettings"  :"page.bank.index.addAccountTitle")}</h5>
            {
              !payload?.account ?( 
                <h6 className="text-muted small" >{t('page.bank.index.accountHintTot')}</h6>
              ):(<span></span>)
            }
            {
            smsCodeData?.success ?(
              <FormSmsValidation
                editData={smsCodeData.response}
                fileUpload={fileUpload}
                setSmsCode={setSmsCode}
                setFileUpload={setFileUpload}
                isCashout={payload?.account?.state == "ACTIVE"}
              />
            )
            :(payload?.account ? ( //--- Faire un Cashout
              payload?.account.state == "ACTIVE" ?(<FormAddCashout
                editData={payload.account}
                setSmsCode={setSmsCode}
                solde={payload?.solde}
              />):(
                <p className="text-center text-info">
                  {t('page.bank.index.stateValidating')}
                </p>
              )
            ) : ( //--- Ajouter Un Compte bancaire 
              <FormAddBankAccount
                editData={user}
                bankType={payload?.banksTypes ? payload.banksTypes : []}
                setSmsCode={setSmsCode}
              />
            ))
            
            }
          </CardBody>
        </Card>
        

        <Card className={payload?.account?.state == 'ACTIVE' ? "":"d-none" }>
          <CardBody>
            <h5>{t("page.bank.index.cashoutHistTitle")}</h5>
            <div className="px-sm-4 py-sm-4 px-1 py-1">
              {payload?.transactions && payload?.transactions.length > 0
              ?(<table className="table table-striped">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Fee</th>
                    <th>Amount Perceived</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {payload.transactions.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.amount} <i>Mru</i></td>
                      <td>{transaction.transaction_fee} <i>Mru</i></td>
                      <td className="fw-bold">{transaction.amount - transaction.transaction_fee} <i className="small">Mru</i></td>
                      <td>{ t( transaction.meta?.slug =="FINISHED" ? 'cashoutHistStateFinished':( transaction.meta?.slug =="REFUNDED" ? 'page.bank.index.cashoutHistStateRefunded':'page.bank.index.cashoutHistStateWaiting'))  }</td>
                    </tr>
                  ))}
                </tbody>
              </table>):(
                <p className="alert alert-info"> {t('page.bank.index.noCashoutTransaction')} </p>
              )}
            </div>
          </CardBody>
        </Card>
      </MainWrapper>
    </>
  );
}
