import React, { useEffect, useState } from "react";
import MainWrapper from "layout-pages/common/MainWrapper";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Badge,
  Form,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { TableHead, TableBody } from "layout-pages/common/Table";
import {
  convertDate,
  formatApiDate,
  numberWithThousandSeparator,
} from "utils/helpers/utils";
import CustomCard from "layout-pages/common/CustomCard";
import { QUERY_TRANSACTIONS } from "utils/constants/react-query";
import { useListQuery } from "utils/hooks/useCrudQuery";
import HocApiData from "utils/hoc/HocApiData";
import {
  ORDER_STATUS_MAPPER,
  TRANSACTION_TYPE_MAPPER,
} from "utils/constants/enum";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

function TransactionHistory() {
  const { t } = useTranslation();
  const tableHead = [
    t("page.common.tableHeading.date"),
    //"Ref",
    t("page.common.tableHeading.customer"),
    t("page.common.tableHeading.customerPhone"),
    t("page.common.tableHeading.merchant"),
    t("page.common.tableHeading.merchantPhone"),
    t("page.common.tableHeading.amount"),
    t("page.common.tableHeading.delivery"),
    t("page.common.tableHeading.type"),
    t("page.common.tableHeading.status"),
    t("page.common.tableHeading.deliveryStatus"),
    t("page.common.tableHeading.actions"),
  ];

  const stats = [
    {
      title: t("page.transactions.history.statsRevenue"),
      iconClass: "bx-copy-alt",
      description: "0 MRU",
      color: "primary",
    },

    {
      title: t("page.transactions.history.statsIncome"),
      iconClass: "bx-purchase-tag-alt",
      description: "0 MRU",
      color: "success",
    },

    {
      title: t("page.transactions.history.statsExpenses"),
      iconClass: "bx-archive-in",
      description: "0 MRU",
      color: "danger",
    },
  ];

  const [url, setUrl] = useState("");
  const [paginationConfig, setPaginationConfig] = useState({});
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState("DESC");
  const [nbPeerPage, setNbPeerPage] = useState(10);

  const [search, setSearch] = useState("");
  const [type, setType] = useState("ALL");
  const [periodFrom, setPeriodFrom] = useState("");
  const [periodTo, setPeriodTo] = useState("");

  useEffect(() => {
    const updatedUrl = `transactions?page=${page}&nb_peer_page=${nbPeerPage}&order_by=${orderBy}&type=${type}`;
    setUrl(updatedUrl);
    setPaginationConfig({
      page,
      nbPeerPage,
      orderBy,
      type,
    });
  }, []);

  useEffect(() => {
    if (!search) {
      let updatedUrl = `transactions?page=${page}&nb_peer_page=${nbPeerPage}&order_by=${orderBy}&type=${type}`;
      if (periodFrom) updatedUrl += `&period_from=${convertDate(periodFrom)}`;
      if (periodTo) updatedUrl += `&period_to=${convertDate(periodTo)}`;
      setUrl(updatedUrl);
      setPaginationConfig({
        page,
        nbPeerPage,
        orderBy,
        type,
        periodFrom,
        periodTo,
      });
    } else {
      const updatedUrl = `transactions?page=${page}&nb_peer_page=${nbPeerPage}&order_by=${orderBy}&search=${search}`;
      setUrl(updatedUrl);
      setPaginationConfig({
        page,
        nbPeerPage,
        orderBy,
        search,
      });
    }
  }, [page, type, nbPeerPage, orderBy, periodFrom, periodTo, search]);

  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useListQuery({
    url: url,
    key: QUERY_TRANSACTIONS,
    isEnabled: !!url,
    paginated: true,
    paginationConfig: paginationConfig,
  });

  return (
    <>
      <MainWrapper
        title={t("page.transactions.history.title")}
        heading={t("page.transactions.history.heading")}
      >
        <HocApiData
          loading={isLoading}
          error={isError}
          isEmpty={payload?.data?.length === 0}
          refetch={refetch}
        >
          <Row>
            {/* Dashboard */}
            {stats.map((st, key) => (
              <Col md="4" key={"_col_" + key}>
                <CustomCard
                  title={st.title}
                  description={st.description}
                  iconClass={st.iconClass}
                  color={st.color}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col lg="12">
              <TransactionsFilter
                pageState={[page, setPage]}
                searchState={[search, setSearch]}
                typeState={[type, setType]}
                periodFromState={[periodFrom, setPeriodFrom]}
                periodToState={[periodTo, setPeriodTo]}
              />
              <div className="table-responsive">
                <Table className="project-list-table table-nowrap align-middle table-borderless mb-0">
                  <TableHead cols={tableHead} />
                  <TableBody data={payload || []} TableRow={TableRow} />
                </Table>
              </div>
              <TransactionPagination
                pageState={[page, setPage]}
                orderByState={[orderBy, setOrderBy]}
                nbPeerPageState={[nbPeerPage, setNbPeerPage]}
              />
            </Col>
          </Row>
        </HocApiData>
      </MainWrapper>
    </>
  );
}

function TransactionPagination({ pageState, orderByState, nbPeerPageState }) {
  const { t } = useTranslation();
  const totalPage = 5;
  return (
    <div className="d-flex flex-wrap justify-content-between mt-3 mb-3">
      <Row style={{ width: "270px" }} className="mb-3">
        <Col xs="5">
          <FormGroup className=" mb-0">
            <select
              className="form-control select2-search-disable"
              onChange={e => nbPeerPageState[1](e.target.value)}
              value={nbPeerPageState[0]}
            >
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
            </select>
          </FormGroup>
        </Col>

        <Col xs="7">
          <FormGroup className="mb-0">
            <select
              className="form-control select2-search-disable"
              onChange={e => orderByState[1](e.target.value)}
              value={orderByState[0]}
            >
              <option value="DESC">
                {t("page.transactions.history.orderByDesc")}
              </option>
              <option value="ASC">
                {t("page.transactions.history.orderByAsc")}
              </option>
            </select>
          </FormGroup>
        </Col>
      </Row>

      <nav>
        <ul className="pagination">
          <li className={pageState[0] > 1 ? "page-item" : "page-item disabled"}>
            <Link
              className="page-link"
              to="#"
              tabIndex="-1"
              onClick={e => {
                if (pageState[0] > 1) {
                  e.preventDefault();
                  pageState[1](pageState[0] - 1);
                }
              }}
            >
              {t("page.common.btn.previous")}
            </Link>
          </li>

          {[1, 2, 3, 4, 5].map(page => {
            return (
              <li
                key={page}
                className={
                  pageState[0] === page ? "page-item active" : "page-item"
                }
              >
                <Link
                  className="page-link"
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    pageState[1](page);
                  }}
                >
                  {page} <span className="sr-only"></span>
                </Link>
              </li>
            );
          })}

          <li
            className={
              pageState[0] < totalPage ? "page-item" : "page-item disabled"
            }
          >
            <Link
              className="page-link"
              to="#"
              onClick={e => {
                if (pageState[0] < totalPage) {
                  e.preventDefault();
                  pageState[1](pageState[0] + 1);
                }
              }}
            >
              {t("page.common.btn.next")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
TransactionPagination.propTypes = {
  pageState: PropTypes.array,
  orderByState: PropTypes.array,
  nbPeerPageState: PropTypes.array,
};

function TransactionsFilter({
  pageState,
  searchState,
  typeState,
  periodFromState,
  periodToState,
}) {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(searchState[0]); // handle search input value change
  useEffect(() => {
    if (searchInput == "" && searchState[0] != "") {
      searchState[1](""); // console.log("Search cleared ...");
    }
  }, [searchInput]);

  return (
    <Row className="mb-4">
      <Col md="8">
        <Form>
          <Row>
            <Col sm="6" className="col-xl">
              <FormGroup className="mt-3 mb-0">
                <Label>{t("forms.input.startDate.label")}</Label>
                <DatePicker
                  selected={periodFromState[0]}
                  onChange={date => periodFromState[1](date)}
                  className="form-control"
                  placeholderText={t("forms.input.startDate.placeholder")}
                  disabled={!!searchState[0]}
                />
              </FormGroup>
            </Col>

            <Col sm="6" className="col-xl">
              <FormGroup className="mt-3 mb-0">
                <Label>{t("forms.input.endDate.label")}</Label>
                <DatePicker
                  selected={periodToState[0]}
                  onChange={date => periodToState[1](date)}
                  className="form-control"
                  placeholderText={t("forms.input.endDate.placeholder")}
                  disabled={!!searchState[0]}
                />
              </FormGroup>
            </Col>

            <Col sm="6" className="col-xl">
              <FormGroup className="mt-3 mb-0">
                <Label>{t("forms.input.typeTransaction.label")}</Label>
                <select
                  className="form-control select2-search-disable"
                  onChange={e => typeState[1](e.target.value)}
                  value={typeState[0]}
                  disabled={!!searchState[0]}
                >
                  <option value="ALL">
                    {t("page.transactions.history.typeTransaction.all")}
                  </option>
                  <option value="PAY">
                    {t("page.transactions.history.typeTransaction.pay")}
                  </option>
                  <option value="CASH_IN">
                    {t("page.transactions.history.typeTransaction.cashin")}
                  </option>
                  <option value="CASH_OUT">
                    {t("page.transactions.history.typeTransaction.cashout")}
                  </option>
                  <option value="TRANSFERT">
                    {t("page.transactions.history.typeTransaction.transfert")}
                  </option>
                </select>
              </FormGroup>
            </Col>

            {/* <Col sm="6" className="col-xl align-self-end">
              <div className="mt-3">
                <Button type="button" color="success" className="w-md">
                  Filtrer
                </Button>
              </div>
            </Col> */}
          </Row>
        </Form>
      </Col>
      <Col md="4">
        <div className="search-box me-2 mt-3">
          <div>
            <Label>{t("forms.input.searchTransaction.label")}</Label>
            <Input
              type="text"
              className="form-control border-0"
              placeholder={t("forms.input.searchTransaction.placeholder")}
              value={searchInput}
              onChange={e => {
                e.target.value.trim()
                  ? setSearchInput(e.target.value)
                  : setSearchInput("");
              }}
              onKeyDown={e => {
                if (e.key === "Enter" && e.target.value.trim()) {
                  // clear filters
                  pageState[1](1);
                  typeState[1]("ALL");
                  periodToState[1]("");
                  periodFromState[1]("");
                  // update search state to fire query
                  searchState[1](e.target.value.trim());
                }
              }}
            />
            {/* <i className="bx bx-search-alt search-icon" /> */}
          </div>
        </div>
      </Col>
    </Row>
  );
}
TransactionsFilter.propTypes = {
  pageState: PropTypes.array,
  searchState: PropTypes.array,
  typeState: PropTypes.array,
  periodFromState: PropTypes.array,
  periodToState: PropTypes.array,
};

function TableRow({ item }) {
  const { t } = useTranslation();
  return (
    <tr>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {formatApiDate(item.created_at)}
        </p>
      </td>
      {/* <td>
        <p className="text-truncate font-size-14 mb-0">{item.id}</p>
      </td> */}
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {`${item?.order.first_name} ${item?.order.last_name}`}
        </p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {item?.order.phone_number}
        </p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {`${item?.customer.first_name} ${item?.customer.last_name}`}
        </p>
      </td>
      <td>
        <p className="text-truncate font-size-14 mb-0">
          {item?.customer.phone_number}
        </p>
      </td>
      <td>
        <h5 className="text-truncate font-size-14 mb-0">
          {numberWithThousandSeparator(item.amount)}{" "}
          <span className="ml-2">MRU</span>
        </h5>
      </td>

      <td>
        <h5 className="text-truncate font-size-14 mb-0">
          {numberWithThousandSeparator(item.transaction_fee)}{" "}
          <span className="ml-2">MRU</span>
        </h5>
      </td>

      <td>
        <Badge
          className={
            "font-size-14 badge-soft-" +
            TRANSACTION_TYPE_MAPPER[item.type]?.backgroundColor
          }
          color={TRANSACTION_TYPE_MAPPER[item.type]?.backgroundColor}
          pill
        >
          {t(TRANSACTION_TYPE_MAPPER[item.type]?.label)}
        </Badge>
      </td>

      <td>
        <Badge
          className={
            "font-size-13 badge-soft-" +
            ORDER_STATUS_MAPPER[item?.meta.slug]?.backgroundColor
          }
          color={ORDER_STATUS_MAPPER[item?.meta.slug]?.backgroundColor}
          pill
        >
          {t(ORDER_STATUS_MAPPER[item.meta.slug]?.label)}
        </Badge>
      </td>

      <td>
        {item.with_delivery ? (
          <Badge
            className={
              item.is_delivered
                ? "font-size-13 badge-soft-success"
                : "font-size-13 badge-soft-warning"
            }
            color={item.is_delivered ? "success" : "warning"}
            pill
          >
            {item.is_delivered
              ? t("page.transactions.history.withDelivery.isDelivered")
              : t("page.transactions.history.withDelivery.isNotDelivered")}
          </Badge>
        ) : (
          "---"
        )}
      </td>
      <td>
        <UncontrolledDropdown style={{ cursor: "pointer" }}>
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem>
              <i className="mdi mdi-eye font-size-16 text-primary me-2" />
              {t("page.common.btn.details")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  item: PropTypes.object,
};

export default TransactionHistory;
