import React from "react";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "reactstrap";
import {
  CustomTextInput,
  CustomTextArea,
} from "../../../../common/GenericInputForm";
import CustomDropzone from "../../../../common/CustomDropzone";
import { useQueryClient } from "react-query";
import PropTypes from "prop-types";
import { asyncPost } from "utils/helpers/api_helper";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { showAlertMsg } from "../../../../../utils/hoc/Alerts";
import { QUERY_PRODUCTS } from "utils/constants/react-query";
import { reactQueryCacheCleaner } from "utils/hooks/useCrudQuery";
import { useTranslation } from "react-i18next";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    title_fr: Yup.string()
      .required("Le nom du produit est obligatoire !")
      .max(50, "Nombre de caractères 50 limite dépassé !"),
    description_fr: Yup.string()
      .required("Une description du produit est obligatoire !")
      .max(1000, "Nombre de caractères 200 limite dépassé !"),
    description_ar: Yup.string()
      .required("Une description du produit en arabe est obligatoire !")
      .max(1000, "Nombre de caractères 200 limite dépassé !"),
    price: Yup.number("Le montant saisi est invalid !")
      .required("Le prix du produit est obligatoire !")
      .min(0, "Le montant saisi est invalid  !"),
    stock: Yup.number("Le stock saisi est invalid !")
      .required("Le stock est obligatoire !")
      .min(1, "Le stock saisi est invalid  !"),
    featured_image: Yup.mixed().required(
      "La photo du produit est obligatoire !"
    ),
    
  }),
  mapPropsToValues: props => ({
    title_fr: props.editData?.title_fr || "",
    description_fr: props.editData?.description_fr || "",
    description_ar: props.editData?.description_ar || "",
    price: props.editData?.price || "",
    stock: props.editData?.stock || "",
    featured_image: "",
    gallaryimage: [],
    summary_fr: props.editData?.summary_fr || "",
    summary_ar: props.editData?.summary_ar || "",
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const payload = {
      ...values,
    };
    payload.shop_id = props.user?.shop?.id;

    /* form data */
    const formData = new FormData();
    formData.append("title_fr", payload.title_fr);
    formData.append("description_fr", payload.description_fr);
    formData.append("description_ar", payload.description_ar);
    formData.append("price", payload.price);
    formData.append("stock", payload.stock);
    formData.append("summary_fr", payload.summary_fr);
    formData.append("summary_ar", payload.summary_ar);
    formData.append("featured_image", payload.featured_image);
    formData.append("shop_id", payload.shop_id);

    // Gérer l'ajout de plusieurs images de la galerie
    if (payload.gallaryimage && payload.gallaryimage.length) {
      
      payload.gallaryimage.forEach((file, index) => {
        // Notez l'utilisation de gallaryimage sans les crochets
        // Le serveur doit être configuré pour accepter les fichiers multiples sous la même clé
        formData.append("gallaryimage[]", file);
      });
      
    }
    //--- async
    asyncPost("products", formData, {
      "Content-Type": "multipart/form-data",
    }).then(res => {
      if (res) {
        setSubmitting(false);
        resetForm();
        showAlertMsg("success", props.t("page.common.toaster.success"));

        if (!props.fromShopPage) {
          // from products page
          reactQueryCacheCleaner({
            queryClient: props.queryClient,
            resetKeys: [QUERY_PRODUCTS],
            invalidateKeys: [],
          });
          props.closeModal();
        } else {
          // from shop page
          props.history.push("/products");
        }
      } else {
        showAlertMsg("danger", props.t("page.common.toaster.error"));
        setSubmitting(false);
      }
    });
  },
  displayName: "MyForm",
});

const MyForm = props => {
  return (
    <Form onSubmit={props.handleSubmit} className="px-sm-4 py-sm-4 px-1 py-1">
      <Row>
        <Col md={12} lg={6}>
          <CustomTextInput
            type="text"
            className="form-control"
            name="title_fr"
            label="Nom du produit *"
          />
          <CustomTextInput
            type="number"
            className="form-control"
            name="price"
            label="Prix du produit *"
          />
          <CustomTextInput
            type="number"
            className="form-control"
            name="stock"
            label="En stock *"
          />
        </Col>
        <Col md={12} lg={6}>
          <CustomTextArea
            type="text"
            className="form-control"
            name="description_fr"
            label="Description *"
            rows={3}
          />

          <CustomTextArea
            type="text"
            className="form-control"
            name="description_ar"
            label="Description * - وصف المنتج (Ar)"
            rows={3}
          />
        </Col>

        <Col md={12} lg={6}>
          <CustomTextArea
            type="text"
            className="form-control"
            name="summary_fr"
            label="Resumé (Fr)"
          />
        </Col>

        <Col md={12} lg={6}>
          <CustomTextArea
            type="text"
            className="form-control"
            name="summary_ar"
            label="Resumé - ملخص المنتج (Ar)"
          />
        </Col>

        <Col md={12} lg={6}>
          <div className="mb-3">
            <label htmlFor="featured_image">Image à la Une du produit *</label>
            <CustomDropzone
              accept="image/*"
              multiple={false}
              maxSize={7000000}
              maxFiles={20}
              placeholder="Ajouter une photo du produit"
              thumbSize={150}
              //
              valueName="featured_image"
              setFieldValue={props.setFieldValue}
            />
            {props.errors.featured_image && props.touched.featured_image && (
              <div style={{ color: "red", marginTop: ".5rem" }}>
                {props.errors.featured_image}
              </div>
            )}
          </div>
        </Col>
        
        <Col md={12} lg={6}>
          <div className="mb-3 ">
            <label htmlFor="gallaryimage">Photo du produit - max 3 (Facultatif)</label>
            <CustomDropzone
              accept="image/*"
              multiple={true}
              maxSize={7000000}
              maxFiles={3}
              placeholder="Ajouter plus de photo au produit"
              thumbSize={150}
              //
              valueName="gallaryimage"
              setFieldValue={props.setFieldValue}
            />
            {props.errors.gallaryimage && props.touched.gallaryimage && (
              <div style={{ color: "red", marginTop: ".5rem" }}>
                {props.errors.gallaryimage}
              </div>
            )}
          </div>
        </Col>
      </Row>

      {props.isSubmitting ? (
        <Button
          color="primary"
          disabled={props.isSubmitting}
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          En cours ...
        </Button>
      ) : (
        <Button
          color="primary"
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          Enregister
        </Button>
      )}
    </Form>
  );
};

MyForm.propTypes = {
  handleSubmit: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  errors: PropTypes.any,
  isSubmitting: PropTypes.any,
  fromShopPage: PropTypes.bool,
  closeModal: PropTypes.func,
};

const MyEnhancedForm = formikEnhancer(MyForm);

const FormAddProduct = props => {
  const queryClient = useQueryClient();
  const user = useSelector(state => state.Login.user);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <MyEnhancedForm
      t={t}
      {...props}
      queryClient={queryClient}
      history={history}
      user={user}
    />
  );
};

export default FormAddProduct;
