import React from "react";
import BalanceHistory from "./components/BalanceHistory";

export default function Balance() {
  return (
    <>
      <BalanceHistory />
    </>
  );
}
