import usFlag from "../../assets/images/flags/us.jpg";
import frFlag from "../../assets/images/flags/french.jpg";
import rimFlag from "../../assets/images/flags/rim.png";

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  fr: {
    label: "Français",
    flag: frFlag,
  },
  ar: {
    label: "عربي",
    flag: rimFlag,
  },
};

export default languages;
