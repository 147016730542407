import React from "react";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "reactstrap";
import { useQueryClient } from "react-query";
import PropTypes from "prop-types";
import { asyncPost } from "utils/helpers/api_helper";
import { showAlertMsg } from "../../../../../utils/hoc/Alerts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertDate, subtractDays, newDate } from "utils/helpers/utils";
import { reactQueryCacheCleaner } from "utils/hooks/useCrudQuery";
import { QUERY_PRODUCTS } from "utils/constants/react-query";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    start_at: Yup.date()
      .min(
        subtractDays(new Date(), 1),
        "La date de début ne peut etre avant aujourd'hui"
      )
      .required("La date de début est obligatoire !"),
    end_at: Yup.date()
      .min(
        Yup.ref("start_at"),
        "La date de fin ne peut etre avant la date de début"
      )
      .required("La date de fin est obligatoire !"),
  }),
  mapPropsToValues: props => ({
    start_at: newDate(props.editData?.start_at) || "",
    end_at: newDate(props.editData?.end_at) || "",
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const payload = {
      ...values,
    };

    payload.shop_id = props.user?.shop?.id;
    payload.product_id = props.product?.id;
    payload.start_at = convertDate(payload.start_at);
    payload.end_at = convertDate(payload.end_at);

    if (!props.product.advert) {
      asyncPost("promotions/products", payload).then(res => {
        if (res) {
          reactQueryCacheCleaner({
            queryClient: props.queryClient,
            resetKeys: [QUERY_PRODUCTS],
            invalidateKeys: [],
          });
          //
          setSubmitting(false);
          resetForm();
          showAlertMsg("success", props.t("page.common.toaster.success"));
          setTimeout(() => {
            showAlertMsg(
              "info",
              props.t("page.common.toaster.promoteWaitForValidation")
            );
          }, 1500);
          props.closeModal(false);
        } else {
          showAlertMsg("danger", props.t("page.common.toaster.error"));
          setSubmitting(false);
        }
      });
    } else {
      if (props.product.advert?.status === "inactive") {
        showAlertMsg("info", props.t("page.common.toaster.promotePending"));
        setSubmitting(false);
      } else {
        showAlertMsg("info", props.t("page.common.toaster.alreadyPromoted"));
        setSubmitting(false);
      }
    }
  },
  displayName: "MyForm",
});

const MyForm = props => {
  return (
    <Form onSubmit={props.handleSubmit} className=" px-1 py-1">
      <Row>
        <Col md={12} lg={12}>
          <div className="form-group my-2">
            <label htmlFor="start_at">Date de debut *</label>
            <DatePicker
              name="start_at"
              className="form-control"
              selected={props.values.start_at}
              dateFormat="yyyy-MM-dd"
              onChange={date => props.setFieldValue("start_at", date)}
              onBlur={() => props.setFieldTouched("start_at", true)}
            />
            {props.errors.start_at && props.touched.start_at && (
              <div style={{ color: "red", marginTop: ".5rem" }}>
                {props.errors.start_at}
              </div>
            )}
          </div>
        </Col>
        <Col md={12} lg={12}>
          <div className="form-group my-2">
            <label htmlFor="end_at">Date de fin *</label>
            <DatePicker
              name="end_at"
              className="form-control"
              selected={props.values.end_at}
              dateFormat="yyyy-MM-dd"
              onChange={date => props.setFieldValue("end_at", date)}
              onBlur={() => props.setFieldTouched("end_at", true)}
            />
            {props.errors.end_at && props.touched.end_at && (
              <div style={{ color: "red", marginTop: ".5rem" }}>
                {props.errors.end_at}
              </div>
            )}
          </div>
        </Col>
      </Row>

      {props.isSubmitting ? (
        <Button
          color="primary"
          disabled={props.isSubmitting}
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          En cours ...
        </Button>
      ) : (
        <Button
          color="primary"
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          Enregister
        </Button>
      )}
    </Form>
  );
};

MyForm.propTypes = {
  values: PropTypes.any,
  handleSubmit: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  errors: PropTypes.any,
  isSubmitting: PropTypes.any,
};

const MyEnhancedForm = formikEnhancer(MyForm);

const FormPromoteProduct = props => {
  const queryClient = useQueryClient();
  const user = useSelector(state => state.Login.user);
  const { t } = useTranslation();
  return (
    <MyEnhancedForm t={t} {...props} queryClient={queryClient} user={user} />
  );
};

export default FormPromoteProduct;
