import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function Insight({ data }) {
  const { t } = useTranslation();
  return (
    <>
      <Col xl={12}>
        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <div className="me-2">
                <h5 className="card-title mb-3">
                  {t("page.dashboard.insight.title")}
                </h5>
              </div>
            </div>

            <ul className="list-group list-group-flush">
              {data?.map((item, index) => {
                const color_ = `#${Math.floor(
                  Math.random() * 16777215
                ).toString(16)}`;

                return (
                  <li className="list-group-item" key={index}>
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {item.label}
                        <span className="float-end">
                          {Math.round(item.percentage) + " %"}
                        </span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: Math.round(item.percentage) + "%",
                            backgroundColor: color_,
                          }}
                          aria-valuenow={Math.round(item.percentage)}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

Insight.propTypes = {
  data: PropTypes.any,
};

export default Insight;
