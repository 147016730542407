import React from "react";
import { withFormik, Form } from "formik";
import * as Yup from "yup";
import { Button, Row, Col } from "reactstrap";
import { SyncLoader } from "react-spinners";
import {
  CustomTextInput,
  CustomTextArea,
} from "../../../../common/GenericInputForm";
import CustomDropzone from "../../../../common/CustomDropzone";
import { useQueryClient } from "react-query";
import PropTypes from "prop-types";
import { asyncPost } from "utils/helpers/api_helper";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ADD_SHOP } from "store/auth/login/actionTypes";
import { showAlertMsg } from "../../../../../utils/hoc/Alerts";
import { useTranslation } from "react-i18next";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Le nom de votre boutique est obligatoire !")
      .max(50, "Nombre de caractères 50 limite dépassé !"),
    description: Yup.string()
      .required("Une description de la boutique est obligatoire !")
      .max(200, "Nombre de caractères 200 limite dépassé !"),
    address: Yup.string()
      .required("L'adresse de la boutique est obligatoire !")
      .max(50, "Nombre de caractères 50 limite dépassé !"),
    logo: Yup.mixed().required("Le logo de la boutique est obligatoire !"),
    city: Yup.object().required(
      "La ville est de la boutique est obligatoire !"
    ),
  }),
  mapPropsToValues: props => ({
    name: "",
    description: "",
    address: "",
    logo: "",
    city: "",
    lat: "",
    lng: "",
  }),
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const payload = {
      ...values,
    };
    payload.city_id = payload.city.value;

    /* form data */
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("description", payload.description);
    formData.append("address", payload.address);
    formData.append("logo", payload.logo);
    formData.append("banner", payload.logo);
    formData.append("city_id", payload.city_id);
    formData.append("lat", payload.lat);
    formData.append("lng", payload.lng);

    asyncPost("shops", formData, {
      "Content-Type": "multipart/form-data",
    }).then(res => {
      if (res) {
        setSubmitting(false);
        resetForm();
        showAlertMsg("success", props.t("page.common.toaster.success"));

        props.history.push("/shops");
        props.dispatch({
          type: ADD_SHOP,
          payload: res?.data?.data,
        });

        setTimeout(() => {
          showAlertMsg("info", props.t("page.common.toaster.congratFirstShop"));
        }, 1500);
      } else {
        showAlertMsg("danger", props.t("page.common.toaster.error"));
        setSubmitting(false);
      }
    });
  },
  displayName: "MyForm",
});

const MyForm = props => {
  return (
    <Form onSubmit={props.handleSubmit} className="px-sm-4 py-sm-4 px-1 py-1">
      <Row>
        <Col md={12} lg={6}>
          <CustomTextInput
            type="text"
            className="form-control"
            name="name"
            label="Nom de la boutique *"
          />
          <CustomTextInput
            type="text"
            className="form-control"
            name="address"
            label="Adresse de la boutique *"
          />
        </Col>
        <Col md={12} lg={6}>
          <CustomTextArea
            type="text"
            className="form-control"
            name="description"
            label="Description *"
          />
        </Col>

        <Col lg={12}>
          <div className="mb-5">
            <label htmlFor={"city"}>Ville *</label>
            <Select
              options={
                props.cities?.map(item => {
                  return {
                    value: item.id,
                    label: item.name_fr,
                  };
                }) || []
              }
              placeholder="Sélectionner la ville ..."
              name="city"
              onChange={value => props.setFieldValue("city", value)}
              onBlur={() => props.setFieldTouched("city", true)}
            />
            {props.errors.city && props.touched.city && (
              <div
                style={{ color: "red", marginTop: ".5rem" }}
                className="mb-3"
              >
                {props.errors.city}
              </div>
            )}
          </div>
        </Col>

        <Col md={12} lg={6}>
          <CustomTextInput
            type="number"
            className="form-control"
            name="lng"
            label="Longitude "
          />
          <CustomTextInput
            type="number"
            className="form-control"
            name="lat"
            label="Latitude "
          />
        </Col>

        <Col md={12} lg={6}>
          <div className="mb-3">
            <label htmlFor="logo">Logo de la boutique *</label>
            <CustomDropzone
              accept="image/*"
              multiple={false}
              maxSize={7000000}
              maxFiles={20}
              placeholder="Ajouter un logo de la boutique"
              thumbSize={150}
              //
              valueName="logo"
              setFieldValue={props.setFieldValue}
            />
            {props.errors.logo && props.touched.logo && (
              <div style={{ color: "red", marginTop: ".5rem" }}>
                {props.errors.logo}
              </div>
            )}
          </div>
        </Col>
      </Row>

      {props.isSubmitting ? (
        <Button
          color="primary"
          disabled={props.isSubmitting}
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          En cours ...
        </Button>
      ) : (
        <Button
          color="primary"
          className="text-white px-3 py-2 mt-3"
          type="submit"
        >
          Enregister
        </Button>
      )}
    </Form>
  );
};

MyForm.propTypes = {
  handleSubmit: PropTypes.any,
  setFieldValue: PropTypes.any,
  setFieldTouched: PropTypes.any,
  errors: PropTypes.any,
  touched: PropTypes.any,
  errors: PropTypes.any,
  isSubmitting: PropTypes.any,
  cities: PropTypes.any,
};

const MyEnhancedForm = formikEnhancer(MyForm);

const FormAddShop = props => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const cities = useSelector(state => state.Ressource.cities.payload);
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <MyEnhancedForm
      t={t}
      {...props}
      queryClient={queryClient}
      dispatch={dispatch}
      history={history}
      cities={cities}
    />
  );
};

export default FormAddShop;
