import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import CustomCard from "layout-pages/common/CustomCard";
import PropTypes from "prop-types";
import { numberWithThousandSeparator } from "utils/helpers/utils";
import { useTranslation } from "react-i18next";

function Statistics({ data }) {
  const { t } = useTranslation();
  const [isMonthly, setIsMonthly] = useState(true);
  const monthlyStats = data?.monthly;
  const yearlyStats = data?.yearly;

  const stats = [
    {
      title: t("page.dashboard.statistics.amountGlobal"),
      iconClass: "bx-archive-in",
      col: "6",
      color: "primary",
      description: isMonthly
        ? monthlyStats?.original?.solde
        : yearlyStats?.original?.solde,
    },
    {
      title: t("page.dashboard.statistics.amountBlocked"),
      iconClass: "bx-archive-in",
      col: "6",
      color: "warning",
      description: isMonthly
        ? monthlyStats?.original?.solde_pending
        : yearlyStats?.original?.solde_pending,
    },
    //
    {
      title: t("page.dashboard.statistics.amountAvailable"),
      iconClass: "bx-copy-alt",
      col: "3",
      color: "success",
      description: isMonthly
        ? monthlyStats?.original?.balance?.available
        : yearlyStats?.original?.balance?.available,
    },
    {
      title: t("page.dashboard.statistics.amountPending"),
      iconClass: "bx-archive-in",
      col: "3",
      color: "warning",
      description: isMonthly
        ? monthlyStats?.original?.balance?.waiting
        : yearlyStats?.original?.balance?.waiting,
    },
    {
      title: t("page.dashboard.statistics.amountCanceled"),
      iconClass: "bx-purchase-tag-alt",
      col: "3",
      color: "danger",
      description: isMonthly
        ? monthlyStats?.original?.balance?.cancelled
        : yearlyStats?.original?.balance?.cancelled,
    },
    {
      title: t("page.dashboard.statistics.amountWithConflicts"),
      iconClass: "bx-copy-alt",
      col: "3",
      color: "primary",
      description: isMonthly
        ? monthlyStats?.original?.balance?.litiges
        : yearlyStats?.original?.balance?.litiges,
    },
    //
    {
      title: t("page.dashboard.statistics.income"),
      iconClass: "bx-purchase-tag-alt",
      col: "6",
      color: "success",
      description: isMonthly
        ? monthlyStats?.original?.balance?.incomes
        : yearlyStats?.original?.balance?.incomes,
    },

    {
      title: t("page.dashboard.statistics.expenses"),
      iconClass: "bx-archive-in",
      col: "6",
      color: "danger",
      description: isMonthly
        ? monthlyStats?.original?.balance?.outcomes
        : yearlyStats?.original?.balance?.outcomes,
    },
  ];

  return (
    <>
      <h4 className="mt-4 mb-2"> {t("page.dashboard.statistics.title")}</h4>
      <Row>
        {stats.map((st, key) => (
          <Col md={st?.col ? st.col : "4"} key={"_col_" + key}>
            <CustomCard
              title={st.title}
              description={
                st.description || st.description === 0
                  ? numberWithThousandSeparator(st.description) + " MRU"
                  : "?? MRU"
              }
              iconClass={st.iconClass}
              color={st.color}
            />
          </Col>
        ))}
      </Row>
    </>
  );
}

Statistics.propTypes = {
  data: PropTypes.object,
};

export default Statistics;
