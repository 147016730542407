import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Alert, Button, Input } from "reactstrap";
import { VALIDATE_CODE_SMS } from "store/auth/login/actionTypes";
import { asyncPost } from "utils/helpers/api_helper";
import { showAlertMsg } from "utils/hoc/Alerts";

export default function AlertConfirmPhoneNumber() {
  const [codeSms, setCodeSms] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const codeSmsSubmit = () => {
    setIsLoading(true);
    asyncPost("validateAccount", { sms_code: codeSms }, {}).then(res => {
      //console.log(res);
      // todo fixme
      if (res?.data?.data) {
        setIsLoading(false);
        dispatch({ type: VALIDATE_CODE_SMS });
        showAlertMsg("success", t("page.common.toaster.success"));
      } else {
        setIsLoading(false);
        showAlertMsg("danger", t("page.common.toaster.error"));
      }
    });
  };

  return (
    <>
      <Alert color="warning" className="p-4">
        <h5 className="mb-3">{t("page.dashboard.alertConfirmPhone.title")}</h5>
        <p className="my-2">{t("page.dashboard.alertConfirmPhone.content")}</p>
        <div className="d-flex items-center justify-content-between mb-3">
          <Input
            className="form-control"
            name="code-sms"
            type="number"
            placeholder={t("forms.input.smsCode.placeholder")}
            disabled={isLoading}
            onChange={e => setCodeSms(e.target.value)}
            value={codeSms}
          />

          <Button
            className="w-lg mx-3"
            color="success"
            type="submit"
            disabled={codeSms.trim() === "" || isLoading}
            onClick={() => codeSmsSubmit()}
          >
            {isLoading
              ? t("page.common.spinner.onLoadingLite")
              : t("page.common.btn.confirm")}
          </Button>
        </div>
      </Alert>
    </>
  );
}
