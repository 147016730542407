import React from "react";
import LitigeHistory from "./components/LitigeHistory";

export default function Litige() {
  return (
    <>
      <LitigeHistory />
    </>
  );
}
