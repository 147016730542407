import React, { useState } from "react";
import MainWrapper from "layout-pages/common/MainWrapper";
import { Card, CardImg, Row, Col, Badge, Button } from "reactstrap";
import { QUERY_SHOPS } from "utils/constants/react-query";
import { useListQuery } from "utils/hooks/useCrudQuery";
import HocApiData from "utils/hoc/HocApiData";
import img_404 from "../../../../assets/images/placeholder.png";
import CustomModal from "layout-pages/common/CustomModal";
import FormAddProduct from "layout-pages/pages/Products/FO/components/FormAddProduct";
import { useHistory } from "react-router-dom";

export default function ShopDetail() {
  const [showAddModal, setShowAddModal] = useState(false);
  const {
    isLoading,
    isError,
    refetch,
    data: payload,
  } = useListQuery({
    url: "shops",
    key: QUERY_SHOPS,
  });
  const history = useHistory();
  const last_index = payload?.data?.length - 1;

  const cdn = process.env.REACT_APP_CDN;
  return (
    <>
      <CustomModal
        title={"Ajouter un nouveau produit"}
        isOpen={showAddModal}
        toggle={() => setShowAddModal(false)}
        size="lg"
        showFooter={false}
        content={
          <FormAddProduct
            fromShopPage={true}
            closeModal={() => setShowAddModal(false)}
          />
        }
      />
      <MainWrapper title={"Ma boutique"} heading={"Ma boutique"}>
        <HocApiData
          loading={isLoading}
          error={isError}
          isEmpty={payload?.data?.length === 0}
          refetch={refetch}
        >
          <div className="d-flex flex-wrap align-items-center justify-content-end mb-3 mt-0">
            <div className="mx-3">
              <Button
                type="button"
                color="primary"
                onClick={() => setShowAddModal(true)}
              >
                <i className="bx bx-plus font-size-18 align-middle me-1"></i>
                Ajouter un Produit
              </Button>
            </div>
            <div>
              <Button
                type="button"
                color="warning"
                onClick={() => history.push("/edit/shop")}
              >
                <i className="bx bx-pencil font-size-18 align-middle me-1"></i>
                Modifier
              </Button>
            </div>
          </div>
          <Card className="p-4">
            <Row>
              <Col md={12} lg={6}>
                <CardImg
                  className="img-fluid"
                  src={
                    payload?.data[last_index].logo
                      ? payload?.data[last_index]?.logo
                      : img_404
                  }
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = img_404;
                    // e.target.style.width = "60px";
                  }}
                  alt="Logo boutique"
                />
              </Col>
              <Col md={12} lg={6}>
                <div className="my-3">
                  <h5>Bannière (970x250 px)</h5>
                  <p className="text-muted">
                    <CardImg
                    className="img-fluid"
                    height="100"
                    src={
                      payload?.data[last_index].banner
                        ? payload?.data[last_index]?.banner
                        : img_404
                    }
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = img_404;
                      // e.target.style.width = "60px";
                    }}
                    alt="Banniere boutique"
                  />
                  </p>
                </div>
                <div className="my-3">
                  <h5>Nom de la boutique</h5>
                  <p className="text-muted">
                    {" "}
                    {payload?.data[last_index]?.name}{" "}
                  </p>
                </div>
                <div className="my-3">
                  <h5>Ville</h5>
                  <p className="text-muted">
                    {payload?.data[last_index]?.city.name_fr}{" "}
                  </p>
                </div>
                <div className="my-3">
                  <h5>Adresse</h5>
                  <p className="text-muted">
                    {payload?.data[last_index]?.address}{" "}
                  </p>
                </div>

                <div className="my-3">
                  <h5>Status</h5>
                  <Badge
                    className={
                      payload?.data[last_index]?.visible
                        ? "font-size-14 badge-soft-success"
                        : "font-size-14 badge-soft-danger"
                    }
                    color={
                      payload?.data[last_index]?.visible ? "success" : "danger"
                    }
                    pill
                  >
                    {payload?.data[last_index]?.visible
                      ? "Publié"
                      : "Non Publié"}
                  </Badge>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="my-4">
                  <h5>Description</h5>
                  <p className="text-muted">
                    {payload?.data[last_index]?.description}{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Card>
        </HocApiData>
      </MainWrapper>
    </>
  );
}
