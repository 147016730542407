export const REACT_QUERY_RETRY = 1;
export const REACT_QUERY_STALE_TIME = 0; // 3 * (60 * 1000); // 3 mins ;
export const REACT_QUERY_CACHE_TIME = 15 * (60 * 1000); // 15 mins ;

/* prefetched ressources */
export const PREFETCHED_CITIES = "PREFETCHED_CITIES";

// shops
export const QUERY_BANKS = "QUERY_BANK";
export const QUERY_BANK_ID = "QUERY_BANK_ID";

// shops
export const QUERY_SHOPS = "QUERY_SHOPS";
export const QUERY_SHOP_ID = "QUERY_SHOP_ID";

// products
export const QUERY_PRODUCTS = "QUERY_PRODUCTS";
export const QUERY_PRODUCT_ID = "QUERY_PRODUCT_ID";

// categories
export const QUERY_CATEGORIES = "QUERY_CATEGORIES";
export const QUERY_CATEGORIE_ID = "QUERY_CATEGORIE_ID";

// transactions
export const QUERY_TRANSACTIONS = "QUERY_TRANSACTIONS";
export const QUERY_TRANSACTION_ID = "QUERY_TRANSACTION_ID";

// litiges
export const QUERY_LITIGES = "QUERY_LITIGES";
export const QUERY_LITIGE_ID = "QUERY_LITIGE_ID";

// Dashboard
export const QUERY_DASHBOARD = "QUERY_DASHBOARD";
